import React from "react";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";


export const FeatureBox = ({ icon, title, children }: { icon : any, title : string, children: string }) => {
  return (
    <Grid
      item
      key={title}
      xs={12}
      sm={6}
      md={4}
      style={{ textAlign: "center", marginBottom:"50px" }}
    >
      
      {icon}
      <Typography variant="h6" sx={{marginBottom: "15px"}}>{title}</Typography>
      <Typography sx={{textAlign: "center"}}>{children}</Typography>

    
    </Grid>
  );
};
