import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const TeamSystemsPrimaryButton = (props: any) => {
  return (
    <Button
      variant="contained"
      sx={{ backgroundColor: "#ffffff", color: "#000000" }}
    >
      {props.children}
    </Button>
  );
};

export const TeamSystemsSecondaryButton = (props: any) => {
  return (
    <Button
      variant="contained"
      sx={{ backgroundColor: "#006600", color: "#ffffff" }}
    >
      {props.children}
    </Button>
  );
};

export const StartNowButton = (props: any) => {
  const navigate = useNavigate();
  return (
    <Button
      className="start_now_button"
      variant="contained"
      size="large"
      data-testid="start_now_button"
      sx={{ backgroundColor: "#006600", color: "#ffffff", height:"80px",  }}
      onClick={() => {
        navigate("/kostenlos-starten")
      }}
    >
      Jetzt kostenlos starten
    </Button>
  );
};
