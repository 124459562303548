
import {gql} from '@apollo/client';


const INITIALIZE_ORGANIZATION_MUTATION = gql`mutation  initializeOrganization($input: OrganizationInitializationInput!) {
    initializeOrganization(input: $input) {
      ok
      errors {
          field
          error
      } 
      organization {
        id
        name
        domainIdentifier
      }
      user {
        id
        username
        email
        
      }
    }
  }`;

  export default INITIALIZE_ORGANIZATION_MUTATION;