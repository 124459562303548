
import {gql} from '@apollo/client';


const FINALIZE_ORGANIZATION_MUTATION = gql`mutation  finalizeOrganization($input: OrganizationFinalizationInput!) {
  finalizeOrganization(input: $input) {
      ok
      errors {
          field
          error
      } 
      organization {
        id
        name
        domainIdentifier
        url
      }
      user {
        id
        username
        email
        
      }
    }
  }`;

  export default FINALIZE_ORGANIZATION_MUTATION;