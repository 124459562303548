import React from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import iosBadge from "../images/ios-badge.svg";
import androidBadge from "../images/google-play-badge.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

const iosLogoStyle = { width: "150px" };
const androidLogoStyle = { width: "150px" };

const DemoInformation = () => {
  // function createData(
  //   role: string,
  //   username: string,
  //   password: string,
  //   description: string
  // ) {
  //   return { role, username, password, description };
  // }

  // const rows = [
  //   createData(
  //     "Spieler",
  //     "spieler",
  //     "spieler",
  //     "So sieht das System für die meisten Personen aus."
  //   ),
  //   createData(
  //     "Trainerin",
  //     "trainer",
  //     "trainer",
  //     "In dieser Rolle kannst Du u.a. Termine und Personen anlegen und bearbeiten."
  //   ),
  //   createData(
  //     "Administrator",
  //     "admin",
  //     "admin",
  //     "Als Administrator kannst Du neue Teams anlegen und das Design des Systems anpassen"
  //   ),
  // ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <Link href="https://demo.teamsystems.de" target="_new">
              <Button

                variant="contained"
                sx={{
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "13px",
                  height: "45px",
                  width: "150px",
                  padding: "5px",
                  borderRadius: "8px",
                }}
              >
                Browser-Version
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <Link href="https://apps.apple.com/de/app/team-systems/id1300773910" target="_new">
              <img
                src={iosBadge}
                style={iosLogoStyle}
                alt="Logo des ios App Stores"
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <Link href="https://play.google.com/store/apps/details?id=com.teamsystems" target="_new">
              <img
                src={androidBadge}
                style={androidLogoStyle}
                alt="Logo des Google Play Stores"
              />
            </Link>
          </Grid>
        </Grid>
      </div>
      <Container maxWidth="lg" sx={{ textAlign: "center" }}>
        <Typography sx={{ marginTop: "30px", marginBottom: "30px" }}>
          In unsere Demo-Version kannst Du unser System sofort ausprobieren. Du
          kannst das System sowohl als Spieler, als Trainerin oder als
          Administorin/Administrator erkunden und alle Funktionen ausprobieren.
          Das System wird jede Nacht wieder auf den Ausgangszustand
          zurückgesetzt. Du kannst mit den angegebenen Login-Daten auch die Apps
          ausprobieren.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <DemoCard
              role="Spieler"
              username="spieler"
              password="spieler"
              description="So sieht das System für die meisten Personen aus. Du siehst alle Eure Termine und kannst zu- und absagen."
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <DemoCard
              role="Trainerin"
              username="coach"
              password="coach"
              description="In dieser Rolle kannst Du u.a. Termine und Personen anlegen und bearbeiten und ein Team verwalten."
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <DemoCard
              role="Administrator"
              username="admin"
              password="admin"
              description="Als Administrator kannst Du neue Teams anlegen und das Design für die Browser Version des Systems anpassen"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const DemoCard = ({
  role,
  username,
  password,
  description,
}: {
  role: string;
  username: string;
  password: string;
  description: string;
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Container maxWidth="sm" sx={{ textAlign: "left" }}>
          <Stack
            direction="column"
            spacing={1}
            sx={{ alignItems: "center", marginTop: "30px" }}
          >
            <Typography
              sx={{ fontSize: 15, fontWeight: 800, marginBottom: "20px" }}
              color="text.secondary"
              gutterBottom
            >
              {role}
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 600,
                color: 'black',
                marginTop: "20px !important",
              }}
            >
              Benutzername{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 200,
                marginTop: "0px !important",
              }}
            >
              {username}
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                marginTop: "20px !important",
                fontWeight: 600,
                color: 'black',
              }}
            >
              Passwort{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 200,
                marginTop: "0px !important",
              }}
            >
              {password}
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                marginTop: "20px !important",
              }}
            >
              Beschreibung{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 200,
                marginTop: "0px !important",
                textAlign: "center",
              }}
            >
              {description}
            </Typography>
          </Stack>

          {/* <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "30px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>
              Alle genannten Features
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "15px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>Werbefrei</Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "15px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>
              Serverstandort Deutschland
            </Typography>
          </Stack>*/}
        </Container>
      </CardContent>
    </Card>
  );
};

export default DemoInformation;
