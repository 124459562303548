
import {gql} from '@apollo/client';


const PASSWORD_FORGOTTEN_MUTATION = gql`mutation SendResettingEmail($input: PasswordForgottenInput!) {
  sendResettingEmail(input: $input) {
    ok
    errors {
      field
      error
    }
  }
}
`;

  export default PASSWORD_FORGOTTEN_MUTATION;