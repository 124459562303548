import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import GET_DOCUMENTATION_QUERY from '../queries/GET_DOCUMENTATION_QUERY';
import { DocumentationSection, DocumentationContent } from '@teamsystems/ts-types/types/general';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import { useMatomo } from '@m4tt72/matomo-tracker-react'






export default function Documentation() {


  const { loading, data, error } = useQuery(GET_DOCUMENTATION_QUERY);
  const { contentSlug } = useParams();
  const [showMenu, setShowMenu] = useState(contentSlug === undefined);

  useEffect(() => {

    if (contentSlug === undefined) {
      setShowMenu(true);
    }

  }, [contentSlug, setShowMenu]);


  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = contentSlug ? contentSlug : "Dokumentation";
    trackPageView();
    trackEvent({
      category: "Documentation",
      action: contentSlug ? "entry" : "index"
    });
  }, [trackPageView, trackEvent, contentSlug ]);


  if (!data || loading) {
    return <div>Lade ... </div>;
  }



  const documentationSections: DocumentationSection[] = data.getDocumentation.documentationSections;
  const slugContentMap: any = {};

  documentationSections.forEach(section => {
    section.documentationContents.forEach(content => {
      slugContentMap[content.slug] = { content, section }
    });
  });



  return (
    <>
      <div id="open_documentation_container" style={{ position: 'sticky', backgroundColor: 'rgb(231, 235, 240)' }}>
        <Button color="success" onClick={() => setShowMenu(true)}>Öffne Dokumentations-Übersicht</Button>
        <hr style={{ position: 'sticky' }} />
      </div>

      <div id="documentation">





        <NavigationSideBar sections={documentationSections} showMenu={showMenu} setShowMenu={setShowMenu} />

        {contentSlug && <NavigationContent content={slugContentMap[contentSlug].content} section={slugContentMap[contentSlug].section} />}

        {/* {!contentSlug && <div>Noch nichts ausgewählt </div>} */}


      </div>
    </>
  );


}


const NavigationContent = ({ content, section }: { content: DocumentationContent, section: DocumentationSection }) => {

  const reg = new RegExp(/%%%([a-zA-Z 0-9]*)%([a-zA-Z\- 0-9]*)%%%/);
  const result = reg.exec(content.content);

  
  

  

  let html =  content.content;
  
  if( result !== null ) {
    //  html = html.replace(result[0], renderToStaticMarkup(<Link  to={"/dokumentation/" + result[2]}> {result[1]}</Link>));
     html = html.replace(result[0], renderToStaticMarkup(<a className="documentation_crosslink" href={"/dokumentation/" + result[2]} >{result[1]}</a>));
  }
  



  return (
    <div id="documentation_content">
      <h1>{section.title}</h1>
      <h2>{content.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
};



const NavigationSideBar = ({ sections, showMenu, setShowMenu }: { sections: DocumentationSection[], showMenu: boolean, setShowMenu: any }) => {

  const navigationSections = sections.map(el => {
    return <NavigationSection key={el.id} section={el} setShowMenu={setShowMenu} />
  });

  return <div id="documentation_menu" className={showMenu ? "active" : ""}>{navigationSections}</div>
}



const NavigationSection = ({ section, setShowMenu }: { section: DocumentationSection, setShowMenu: any }) => {

  const navigationContentLinks = section.documentationContents.map(el => {
    return <div key={el.slug} className="navigation_link_container" ><Link className="navigation_link" onClick={() => {
      setShowMenu(false); window.scrollTo(0, 0)
    }} to={"/dokumentation/" + el.slug}> {el.title}</Link></div>
  });
  return <div className="navigation_section" >
    <div className="navigation_section_title">{section.title}</div>
    {navigationContentLinks}
  </div>
}

