import * as React from "react";

import {
  StartNowButton,

} from "./TeamSystemsComponents";
import Divider from "@mui/material/Divider";


import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import logo from "../images/teamsystems-logo-green-transparent-302x143-min.png";

import DemoInformation from "./DemoInformation";
import PremiumInformation from "./PremiumInformation";
import Features from "./Features";
import { useMatomo } from '@m4tt72/matomo-tracker-react'


const TSBackgroundColor = "#006600";

const Home = () => {

  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView()
  }, [trackPageView]);


  return (
    <>
      <section id="start"></section>


      <Box
        sx={{
          bgcolor: TSBackgroundColor,
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="white"
            paragraph
            style={{ fontFamily: "Arial" }}
          >
            DER GANZE VEREIN - ALLE MANNSCHAFTEN - ALLE PERSONEN
          </Typography>

          <Typography
            variant="h5"
            align="center"
            color="white"
            paragraph
            style={{ fontFamily: "Arial" }}
          >
            EIN SYSTEM
          </Typography>
          <img src={logo} alt="TeamSystems Logo" style={{ maxWidth: '302px', width: '70%' }} />
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          ></Stack>
        </Container>
      </Box>

      <main id="main">
        <Container sx={{ py: 8, textAlign: "center" }} maxWidth="md" id="header_container">
          <StartNowButton />
        </Container>

        <Features />

        <Divider />
        <Container sx={{ py: 8 }} maxWidth="lg">
          <section id="demo">
            <Typography
              variant="h6"
              sx={{ textAlign: "center", fontWeight: 500, marginBottom: "20px" }}
            >
              Noch nicht überzeugt?
            </Typography>
            <Typography
              variant="h2"
              sx={{ textAlign: "center", fontWeight: 500, marginBottom: "40px" }}
            >
              Demo-Version
            </Typography>
            <DemoInformation />
            {/* <Grid container spacing={3}> */}

            {/* </Grid> */}
          </section>
        </Container>

        <Divider />

        <Container sx={{ py: 8 }} maxWidth="lg">
          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontWeight: 500, marginBottom: "40px" }}
          >
            Premium
          </Typography>
          <PremiumInformation />
        </Container>

        <Divider />
      </main>
    </>
  );
}


export default Home;