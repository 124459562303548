import {gql} from '@apollo/client';


const GET_DOCUMENTATION_QUERY = gql`query getDocumentation {
    getDocumentation {
        ok
        errors {
            field
            error
        }
        documentationSections {
            id
            title
            documentationContents {
                id
                title
                content
                slug
            }
        }
        
    }
  }`

  export default GET_DOCUMENTATION_QUERY;