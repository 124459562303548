import React, { ReactNode, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMatomo } from '@m4tt72/matomo-tracker-react'




interface FaqItem {
  question: string;
  answer: string;
}

export default function Support() {

  const { trackPageView } = useMatomo();

  React.useEffect(() => {

    document.title = "FAQ";
    trackPageView();

  }, [trackPageView]);

  const sections = [{
    headline: "Anmeldung",
    items: [
      { question: "Ist TeamSystems kostenlos?", answer: "Ja, wenn Du mit TeamSystems nur 3 oder weniger Mannschaften verwaltest, ist die Nutzung dauerhaft kostenlos. Erst wenn Du zufrieden bist und mehr als drei Mannschaften anlegst, musst Du Dich für eine kostenpflichtige Nutzung entscheiden." },
      { question: "Ich bin 'nur' Trainer*in, Betreuer*in oder engagiertes Elternteil. Darf ich TeamSystems trotzdem für meinen Verein ausprobieren? ", answer: "Na klar. Das kannst Du sehr gerne machen und es dann anderen Teams im Verein vorschlagen. Wenn Ihr dann mehr als drei Mannschaften seid, schreiben wir Euch eh an, wer der offizielle Ansprechpartner von Vereinsseite ist." },
      { question: "Können wir die Internetseite, unter der meine Vereinsseite erreichbar ist, hinterher noch mal ändern.", answer: "Ja das ist grundsätzlich möglich. Bitte schicke uns dafür eine Email an support@teamsystems.de. Wir sorgen dann auch dafür, dass diejenigen, die die alte Adresse eingeben, korrekt weitergeleitet werden" },
      { question: "Ich möchte mehr als drei Teams verwalten. Wie läuft die Bezahlung ab?", answer: "Wir kontaktieren Dich rechtzeitig, wenn Du über längere Zeit mehr als drei Mannschaften verwaltest. Dann erfragen wir auch den offiziellen Ansprechpartner und die Rechnungsadresse. Der Rechnungsbetrag muss dann auf unser Konto überwiesen werden." },

    ]
  },

  {
    headline: "Datenschutz",
    items: [
      { question: "Was passiert mit den Daten in TeamSystems?", answer: "Die Daten werden nur auf deutschen Servern bei DSGVO konformen Serveranbietern gespeichert. Die Daten werden nur zur Erfüllung von bestimmten Funktionen weitergegeben. Details hierzu findest Du in unserer Datenschutzbestimmungen." },
      { question: "Können wir einen Vertrags zur Auftragsdatenverarbeitung schließen.", answer: "Na klar, Du findest Details dazu in unseren Datenschutzbestimmungen" },
      { question: "Ist TeamSystems DSGVO konform?", answer: "Absolut. In vielen Vereinen unserer Kunden arbeiten Anwälte die uns hier schon komplett abgeklopft haben." },

    ]
  },

  {
    headline: "Fragen zum Tool",
    items: [
      { question: "Ich wünsche mir eine bestimmte Funktion, die es noch nicht gibt. An wen wende ich mich?", answer: "Schreibe uns gerne eine Email an feature@teamsystems.de. Wir versuchen neue Wünsche schnell zu berücksichtigen, aber bei der Vielzahl von Anfragen, können wir natürlich nicht alles zeitnah abarbeiten" },
      { question: "Ich habe einen Fehler gefunden. An wen wende ich mich?", answer: "Bitte schreib uns eine Email an support@teamsystems.de" },


    ]
  },
  ];


  const faqSections: any = [];
  sections.forEach(section => {
    faqSections.push(<AccordionSection headline={section.headline} items={section.items} key={section.headline}/>);
  })

  return (
    <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto', marginTop: '20px' }}>
      {faqSections}
    </div>
  )
}

const AccordionSection = ({ headline, items }: { headline: string, items: FaqItem[] }) => {
  const faqComponents: ReactNode[] = [];
  items.forEach(el => {
    faqComponents.push(<AccordionItem question={el.question} answer={el.answer} key={el.question} />);
  });

  return (
    <div style={{ marginBottom: '50px' }} >
      <Typography variant="h5" style={{ marginBottom: "10px" }}>
        {headline}
      </Typography>
      {faqComponents}
    </div>
  );
}


const AccordionItem = ({ question, answer }: { question: string, answer: string }) => {

  const [itemExpanded, setItemExpanded] = useState(false);

  const {  trackEvent } = useMatomo();



  const handleChange =
    (question: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {


      const newValue = isExpanded ? true : false;

      const trackAction = newValue ? 'open ' + question : 'close ' + question

      trackEvent({
        category: "FAQ",
        action: trackAction
      });
      setItemExpanded(newValue);
    };


  return (
    <Accordion expanded={itemExpanded} onChange={handleChange(question)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography style={{ fontWeight: 800 }}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}