import React from 'react';


const FormError = ({children, testid, style = {}}: {children :any, testid: string, style?: any }) => {
    return (
    <div data-testid={testid} style={{color:"#d32f2f", marginTop: "10px", ...style}}>
        {children}
    </div>
    );
}

export default FormError;