import React, {  useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from "@mui/material/TextField";
import FormError from "./FormError";
import Grid from "@mui/material/Grid";

import { Button, Checkbox, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {  useMutation } from "@apollo/client";
import {  useParams } from "react-router-dom";
import FINALIZE_ORGANIZATION_MUTATION from "../mutations/FINALIZE_ORGANIZATION_MUTATION";
import { useSetSnackbar } from "../hooks/useSnackbar";
import { useMatomo } from '@m4tt72/matomo-tracker-react'


interface IFormInput {
  firstname: string;
  lastname: string;
  password: string;
  acceptTacAndPrivacy: boolean;
}

export default function SignUpFormStep2() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [headline, setHeadline] = useState<string>("Fast geschafft");
  const { trackEvent } = useMatomo();


  const setSnackbar : any = useSetSnackbar();


  const { confirmationToken } = useParams();
  const schema = getValidationSchema();

  const [finalizeOrganization] = useMutation(FINALIZE_ORGANIZATION_MUTATION);



  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { firstname: "", lastname: "", password: "", acceptTacAndPrivacy: false  },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {




    setIsSubmitting(true);


    const mutationResult = await finalizeOrganization({
      variables: {
        input: {
          userConfirmationToken: confirmationToken,
          firstname: data.firstname,
          lastname: data.lastname,
          password: data.password,
          acceptTacAndPrivacy: data.acceptTacAndPrivacy

        }
      }
    });


    if (mutationResult.data?.finalizeOrganization?.ok === true ) {

      setUrl(mutationResult.data.finalizeOrganization.organization.url);
      setUsername(mutationResult.data.finalizeOrganization.user.username);
      setSnackbar("Dein System wurde erfolgreich angelegt.", "success" );
      trackEvent({
        category: "Signup",
        action: "signup-completed"
      });

     // navigate("/start-check-email");
    } else {

      const errorMessage = mutationResult.data?.finalizeOrganization?.errors ? mutationResult.data?.finalizeOrganization?.errors.join(". ") : "Es sind unerwartete Fehler aufgetreten";
      setSnackbar(errorMessage, "error" );

    }


    setIsSubmitting(false);
    setHeadline("Geschafft !!!");
    setIsCompleted(true);
  };

  if (isCompleted) {
    return (
      <>
        <Headline headline={headline} />
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <Typography>
            Dein System ist fertig. Du kannst Dich unter <Link id="link_to_organization" sx={{active: {}}} underline="always" href={url}>{url}</Link> einloggen.<br/><br/>
            Dein Benutzername ist: <b>{username}</b>
          </Typography>
        </Stack>
      </>
    );
  }

  if (isSubmitting) {
    return (
      <>
        <Headline headline={headline} />

        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <CircularProgress />
          <Typography>"Das System wird vorbereitet ... "</Typography>
        </Stack>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Headline headline={headline} />
      <Typography sx={{ marginBottom: "20px" }} variant="h6">
        Bitte gebe Deinen Namen und ein Passwort zum Einloggen an.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Vorname"
                inputProps={{ "data-testid": "input_firstname" }}
                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.firstname && (
            <FormError testid="error_input_firstname">
              Bitte gib einen Vornamen an.
            </FormError>
          )}
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Nachname"
                inputProps={{ "data-testid": "input_lastname" }}
                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.lastname && (
            <FormError testid="error_input_lastname">
              Bitte gib einen Nachnamen an.
            </FormError>
          )}
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Passwort"
                type="password"
                inputProps={{ "data-testid": "input_password" }}
                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.password && (
            <FormError testid="error_input_password">
              Bitte gib ein Passwort mit mindestens 6 Zeichen an.
            </FormError>
          )}
        </Grid>

        <Grid item xs={12}>
  <Controller
    name="acceptTacAndPrivacy"
    control={control}
    rules={{ required: true }}
    render={({ field }) => (
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            color="primary"
            data-testid="input_terms"
          />
        }
        label={
          <>
            Ich akzeptiere die {" "}
            <a href="/agb" target="_blank" rel="noopener noreferrer">
              AGBs
            </a>{" "}
            und {" "}
            <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
              Datenschutzbestimmungen
            </a>.
          </>
        }
      />
    )}
  />
  {errors.acceptTacAndPrivacy && (
    <FormError testid="error_input_acceptTacAndPrivacy">
      Du musst die AGBs und die Datenschutzbestimmungen akzeptieren.
    </FormError>
  )}
</Grid>

{/* 
        <Grid item xs={12}>
          <Controller
            name="acceptTacAndPrivacy"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                size="small"
                label="Passwort"
                type="password"
                dataTestId="input_checkbox_tac_and_privacy"
                // inputProps={{ "data-testid": "input_password" }}
                // style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.password && (
            <FormError testid="error_input_password">
              Bitte gib ein Passwort mit mindestens 6 Zeichen an.
            </FormError>
          )}
        </Grid> */}

        <Grid item xs={12} style={{ alignItems: "center" }}>
          <Button
            variant="contained"
            data-testid="step2_submit_button"
            size="large"
            type="submit"
            sx={{ backgroundColor: "#006600", color: "#ffffff" }}
            onClick={() => {
              trackEvent({
                category: "Signup",
                action: "step2-submit"
              });
              handleSubmit(onSubmit);
            }}
          >
            Abschicken
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const Headline = ({ headline }: { headline: string }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        textAlign: "center",
        fontWeight: 500,
        marginBottom: "20px",
        marginTop: "40px",
      }}
    >
      {headline}
    </Typography>
  );
};

export const getValidationSchema = () => {
  const yupValidation: any = {
    firstname: yup.string().required().trim().min(1).max(100),
    lastname: yup.string().required().trim().min(1).max(100),

    password: yup.string().required().trim().min(6).max(100),
    acceptTacAndPrivacy: yup.boolean().oneOf([true], 'You must accept the Terms and Conditions and Privacy Policy to proceed.')
    .required(),

  };

  return yup.object().shape(yupValidation).required();
};
