import React, { SyntheticEvent, useState, useCallback } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormError from "./FormError";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";

import _ from "lodash";
import IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY from "../queries/IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY";
import INITIALIZE_ORGANIZATION_MUTATION from "../mutations/INITIALIZE_ORGANIZATION_MUTATION";
import { useSetSnackbar } from "../hooks/useSnackbar";
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import LOGIN_MUTATION from "../mutations/LOGIN_MUTATION";
import GET_ORGANIZATION_QUERY from "../queries/GET_ORGANIZATION_QUERY";
import { Visibility, VisibilityOff } from "@mui/icons-material";


interface IFormInput {
  username: string;
  password: string;
}

export default function LoginForm() {


  const { trackEvent } = useMatomo();

  const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

  const setSnackbar: any = useSetSnackbar();


  const [
    getOrganization,
    { loading: organizationLoading, data: organizationData },
  ] = useLazyQuery(GET_ORGANIZATION_QUERY);


  const [login, {
    loading
  }] = useMutation(LOGIN_MUTATION);


  const navigate = useNavigate();


  const schema = getValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { username: "", password: "" },

    resolver: yupResolver(schema),
  });




  const onSubmit: SubmitHandler<IFormInput> = async (data) => {


  

    const mutationResult = await login({
      variables: {
        input: {
          username: data.username,
          password: data.password
        }
      }
    });

    if (mutationResult.data?.login?.ok === true) {


      setSnackbar("Dein Login war erfolgreich. Du wirst jetzt zu Eurer Webseite weitergeleitet", "success");


      const organizationResult = await getOrganization({
        context: {
          headers: {
            "authorization": mutationResult.data.login.token
          }
        }
      });


      if( organizationResult.data?.getOrganization?.ok ) {

        const token = mutationResult.data.login.token;
        const baseUrl = organizationResult.data.getOrganization.organization.url;
        const redirectUrl = baseUrl + (process.env.NODE_ENV === "development" ? "/app_dev.php " : "" ) + "/redirect-and-login/" + token + "/" + Math.round(Math.random() * 100000000); 
        window.location.href = redirectUrl;
      } else {

        setSnackbar("Der Login war erfolgreich. Aber beim weiterleiten gab es ein Problem. Bitte probiere es noch einmal oder schreibe uns an support@teamsystems.de.", "error");
      }
      
    } else {

      const errorMessage = mutationResult.data?.login?.errors ? mutationResult.data?.login?.errors : "Es sind unerwartete Fehler aufgetreten";
      setSnackbar(errorMessage, "error");

    }



  };

  if (organizationLoading) {
    return (
      <>
        <Typography sx={{ marginBottom: "20px", marginTop:"20px" }} >Der Login war erfolgreich. Du wirst jetzt weitergeleitet.

        </Typography>
        <CircularProgress />
      </>
    );
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)}>


      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: 500,
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        Einloggen
      </Typography>

      <Typography sx={{ marginBottom: "20px" }} >Jeder Verein/jede Organisation hat seine/ihre eigene Internseite.
        Nach dem erfolgreichen Einloggen, wirst Du direkt auf diese Seite weitergeleitet.

      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Benutzername"
                inputProps={{ "data-testid": "input_username" }}

                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.username && (
            <FormError testid="error_input_username">
              Bitte gib Deinen Benutzernamen an.
            </FormError>
          )}
        </Grid>


        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField

                {...field}

                size="small"
                type={showPassword ? 'text' : 'password'}
                label="Passwort"
                inputProps={{ "data-testid": "input_password" }}

                style={{ width: "100%", backgroundColor:'white' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
                }}

              />
            )}
          />
          {errors.password && (
            <FormError testid="error_input_password" style={{ textAlign: "left" }}>
              Bitte gib Dein Passwort an.
            </FormError>
          )}




        </Grid>

        <Grid item xs={12}>
            <Typography><Link to="/passwort-vergessen">Passwort vergessen?</Link> </Typography>

        </Grid>



        <Grid item xs={12} style={{ alignItems: "center" }}>

          {!loading &&
            <Button
              variant="contained"
              size="large"
              type="submit"
              data-testid="step1_submit_button"
              sx={{ backgroundColor: "#006600", color: "#ffffff" }}
              onClick={() => {
                trackEvent({
                  category: "Login",
                  action: "login-submit"
                });
                handleSubmit(onSubmit);
              }}
            >
              Einloggen
            </Button>
          }

          {loading &&
            <CircularProgress />
          }
        </Grid>
      </Grid>

    </form>
  );
}



export const getValidationSchema = () => {
  const yupValidation: any = {
    username: yup.string().required().trim().min(2).max(100),
    password: yup
      .string()
      .required()
      .trim()
      .min(2)
    ,

  };

  return yup.object().shape(yupValidation).required();
};
