import React from "react";
import Container from "@mui/material/Container";
import logo from "../images/teamsystems-logo-transparent-black-green-257x120.png";
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import PasswordForgottenForm from "./PasswordForgottenForm";



export default function PasswordForgotten() {

  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = "Passwort vergessen";
    trackPageView();
    trackEvent({
      category: "Login",
      action: "password-forgotten"
    });
  }, [trackPageView, trackEvent ]);




  return (
    <Container maxWidth="sm" sx={{ textAlign: "center" }} id="signup">
      <img src={logo} alt="TeamSystems Logo" style={{marginTop: "30px"}} />
     

      

      <PasswordForgottenForm />
    </Container>
  );
}
