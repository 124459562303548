import React, {createContext, useState, memo} from 'react';


export const SnackbarValueContext = createContext({});
export const SnackbarSetContext = createContext(() => {});


export const SnackbarContext = createContext({
    setSnackbar: () => {

    },
    snackbar: {}
});


const SnackbarProvider = memo( ({setSnackbar, children}) => {

    const handleSnackbarSet = (message, type = "info", closeAfterXMs = 8000 ) => {
        setSnackbar({
            message, type, closeAfterXMs
        });
    }

    return (
        <SnackbarSetContext.Provider value={handleSnackbarSet}>
            {children}
        </SnackbarSetContext.Provider>
    )
});

export const SnackbarContainer = ({children}) => {

    const [snackbar, setSnackbar] = useState({
        message: '',
        type: 'info'
      });


       return (
        <SnackbarValueContext.Provider value={snackbar}>
            <SnackbarProvider setSnackbar={setSnackbar}>
            {children}
            </SnackbarProvider>
        </SnackbarValueContext.Provider>
    )
}

