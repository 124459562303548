import {gql} from '@apollo/client';


const IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY = gql`query isDomainIdentifierAvailable( $domainIdentifier: String!) {
    isDomainIdentifierAvailable( domainIdentifier: $domainIdentifier ) {
        ok
        errors {
            field
            error
        }
        
    }
  }`

  export default IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY;