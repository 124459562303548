import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormError from "./FormError";
import Grid from "@mui/material/Grid";


import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import { useSetSnackbar } from "../hooks/useSnackbar";
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import PASSWORD_FORGOTTEN_MUTATION from "../mutations/PASSWORD_FORGOTTEN_MUTATION";



export const getValidationSchema = () => {
  const yupValidation: any = {
    username: yup.string().required().trim().min(2).max(100),
  };

  return yup.object().shape(yupValidation).required();
}



interface IFormInput {
  username: string;
}

export default function PasswordForgottenForm() {

  const [emailSent, setEmailSent] = useState(false);
  const { trackEvent } = useMatomo();

  const setSnackbar: any = useSetSnackbar();


  const [sendResetEmail, {
    loading
  }] = useMutation(PASSWORD_FORGOTTEN_MUTATION);




  const schema = getValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { username: "" },

    resolver: yupResolver(schema),
  });




  const onSubmit: SubmitHandler<IFormInput> = async (data) => {




    const mutationResult = await sendResetEmail({
      variables: {
        input: {
          username: data.username
        }
      }
    });

    if (mutationResult.data?.sendResettingEmail?.ok === true) {


      setSnackbar("Wir haben Dir eine Email mit einem Link zum setzen des Passworts geschickt.", "success");

      setEmailSent(true);
    } else {
      setSnackbar("Wir haben keinen Benutzer mit diesem Benutzernamen oder Email gefunden.", "error");

    };
  }

  if (emailSent) {
    return (
      <>
        <Typography sx={{ marginBottom: "20px", marginTop: "20px" }} >
          Wir haben Dir eine Email mit einem Link zum setzen des Passworts geschickt.

        </Typography>
      </>
    );
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)}>


      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: 500,
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        Passwort vergessen?
      </Typography>

      <Typography sx={{ marginBottom: "20px" }} >Nach Eingabe Deines Benutzernamens oder der Email-Adresse die mit Deinem Benutzernamen verknüpft ist,
        schicken wir Dir einen Link um ein neues Passwort zu setzen.

      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Benutzername oder Email"
                inputProps={{ "data-testid": "input_username" }}

                style={{ width: "100%", backgroundColor: 'white' }}
              />
            )}
          />
          {errors.username && (
            <FormError testid="error_input_username">
              Bitte gib Deinen Benutzernamen oder Email-Adresse an.
            </FormError>
          )}
        </Grid>


        <Grid item xs={12} style={{ alignItems: "center" }}>

          {!loading &&
            <Button
              variant="contained"
              size="large"
              type="submit"
              data-testid="step1_submit_button"
              sx={{ backgroundColor: "#006600", color: "#ffffff" }}
              onClick={() => {
                trackEvent({
                  category: "Login",
                  action: "login-submit"
                });
                handleSubmit(onSubmit);
              }}
            >
              Passwort neu setzen
            </Button>
          }

          {loading &&
            <CircularProgress />
          }
        </Grid>
      </Grid>

    </form>
  );
};



