import React from "react";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import {useSnackbar} from "../hooks/useSnackbar";



const GlobalFeedbackSnackbar = (props) => {


  const { snackbar, setSnackbar } = useSnackbar();




  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar('');
  };

  const { message , type, closeAfterXMs  } = snackbar;


  return (
    <div id="snackbar_container">
   
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={!!message}
        autoHideDuration={closeAfterXMs}
        onClose={handleClose}
        key={props.key}
        data-testid="global_snackbar"

        action={[
          <IconButton
            key="close"
            color="inherit"
            style={{padding: 2}}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          data-testid={`snackbar_${type}`}
          variant="filled"
        >
          <div dangerouslySetInnerHTML={{ __html: message}}></div>
        </Alert>
      </Snackbar>
    </div>
  );
};




export default GlobalFeedbackSnackbar;
