import {gql} from '@apollo/client';


const GET_ORGANIZATION_QUERY = gql`query getOrganization {
    getOrganization {
        ok
        errors {
            field
            error
        }
        organization {
        
          id
          name
          domainIdentifier
          domain
          restrictedStartingFrom
          url
        }
    }
  }`

  export default GET_ORGANIZATION_QUERY;