import {gql} from '@apollo/client';


const GET_BLOG_ENTRIES_QUERY = gql`query getBlogEntries {
    getBlogEntries {
        ok
        errors {
            field
            error
        }
        blogEntries {
            id
            publishedAt
            headline
            content
            slug
            image
            
        }
        
    }
  }`

  export default GET_BLOG_ENTRIES_QUERY;