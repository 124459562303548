import React, { useEffect } from 'react';
import { useMatomo } from '@m4tt72/matomo-tracker-react'

export default function TaC() {

    const {trackPageView} = useMatomo();
    useEffect( () => {
        document.title = 'AGB';
        trackPageView();
    });

    return (
        <div style={{padding:'20px'}}>

            <h2>Allgemeine Geschäftsbedingungen</h2>
            Stand vom 22.02.2023

            <br /><br />
            <table style={{width:'90%'}}>
                <tr>
                    <td colSpan={4}><b>
                        &sect; 1 Vertragsparteien
                    </b></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Dieser Vertrag wird zwischen der Firma TeamSystems (nachfolgend TeamSystems genannt) und dem Kunden
                        abgeschlossen.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}><b>
                        &sect; 2 Geltungsbereich der Allgemeinen Geschäftsbedingungen
                    </b></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Die Allgemeinen Geschäftsbedingungen von TeamSystems gelten für den gesamten Umfang der Dienstleistungen und
                        Lieferungen, die sie dem Kunden gegenüber erbringt. Sie gelten auch für alle Geschäfte, die in Zukunft
                        getätigt werden, auch wenn nicht ausdrücklich darauf Bezug genommen wird.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 3 Gegenstand des Vertrages
                        </b>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        TeamSystems betreibt Software auf Computern, die ständig an das Internet
                        angebunden sind (Server, Webserver). Sie stellt die Software dem Kunden
                        mietweise zur Verfügung. Die auf dem Webserver abgelegten Informationen
                        können weltweit über das Internet abgerufen werden. Vertragsgegenstand
                        ist ebenfalls die Bereitstellung sowie die Realisierung von
                        Dienstleistungen rund um das Internet durch TeamSystems für die jeweils
                        vom Kunden beantragten Leistungen. TeamSystems ermöglicht grundsätzlich
                        den Abruf bzw. die Hinterlegung von Internetseiten, Daten und
                        graphischen Darstellungen, die vom Kunden oder anderen Anbietern sowie
                        aus anderen Diensten und Netzen im Internet angeboten werdenm.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 4 Leistungen von TeamSystems
                        </b>
                    </td>
                </tr>
                <tr>
                    <td style={{width:'20px'}}></td>
                    <td style={{width:'20px'}} valign='top'><b>a.</b></td>
                    <td colSpan={3}>TeamSystems erbringt selbst oder durch Dritte Leistungen nach näherer Maßgabe des im Internet
                        zum Zeitpunkt der Bestellung publizierten Leistungsangebotes. Die im Leistungsangebot beispielhaft
                        aufgeführten Sonderleistungen erbringt TeamSystems nach näherer Absprache. Sonderleistungen werden nach
                        Zeitaufwand mit festen Stundensätzen berechnet. Zusätzlich stellt TeamSystems eigene Aufwendungen in
                        Rechnung.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        Interessenten, die über einen Internetzugang verfügen, können die auf
                        dem Server abgelegten Informationen des Kunden rund um die Uhr
                        kostenfrei abrufen. TeamSystems sagt eine Erreichbarkeit der Server von
                        99 % im Jahresmittel zu. Hiervon ausgenommen sind Zeiten, in denen der
                        Webserver aufgrund von technischen oder sonstigen Problemen, die nicht
                        im Einflussbereich von TeamSystems liegen (höhere Gewalt, Verschulden
                        Dritter etc.), über das Internet nicht zu erreichen ist.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        TeamSystems stellt dem Kunden einen Administrationszugang zur gemieteten Software bereit. Mit diesen
                        Zugangsdaten kann der Kunde das Programm in vollem Umfang nutzen bzw. weitere Nutzer anlegen, die das
                        Programm nutzen.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 5 Leistungen des Kunden
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Für die in &sect; 4 bezeichneten Leistungen zahlt der Kunde die im Leistungsangebot ausgewiesenen Preise im
                        Voraus. Bei Produkten für die ein Testzeitraum vereinbart wurde, muss der Kunde aktiv vor Ablauf des
                        Testzeitraumes bestätigen, dass er die Leistungen kostenpflichtig weiterführen möchte.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        TeamSystems stellt ihre Leistungen halbjährlich in Rechnung, wenn im Angebot keine
                        anderen Zahlungsvereinbarungen ( vierteljährlich, jährlich, etc.)
                        getroffen worden sind. Die Rechnungslegung erfolgt in elektronischer Form,
                        andere Rechnungswege müssen separat vereinbart werden und werden dem Kunden
                        gesondert berechnet. Der Kunde verpflichtet sich, die Rechnungen zum
                        Leistungsbeginn auf das Konto der Firma TeamSystems zu überweisen. Vom Kunden
                        geleistete Zahlungen werden durch TeamSystems nicht zurückerstattet, dies gilt
                        insbesondere bei einer vorzeitigen Auflösung des Vertrags. Für den Fall, dass
                        der Kunde seine Zahlungsverpflichtung nicht rechtzeitig erfüllt, schuldet er -
                        sofern er Kaufmann ist - vom Fälligkeitszeitpunkt an zusätzlich Zinsen in Höhe von 8%-Punkte über dem
                        Basiszins der Europäischen Zentralbank (EZB). Sofern der Kunde Nicht-Kaufmann ist, schuldet er im Falle des
                        Verzugs Zinsen in Höhe von 5%-Punkte über dem Basiszins der EZB.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Der Kunde stellt sicher, dass weder er selbst noch ein Dritter die durch
                        TeamSystems erbrachten Dienstleistungen für schädliche Zwecke missbraucht.
                        Hierzu zählt insbesondere die Verbreitung von verfassungswidrigen, pornographischen oder
                        menschenrechtsverletzenden Inhalten. TeamSystems ist berechtigt, dem Kunden die Bearbeitung gerechtfertigter
                        Beschwerden zu 25,00 EUR je Beschwerde zu berechnen.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 6 Dauer des Vertrages, Kündigung, Sperrung
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Der Vertrag beginnt mit Bestätigung der Bestellung des Kunden auf der Webseite
                        www.teamsystems.de oder mit Bereitstellung der Dienstleistung im
                        Internet und wird zunächst für dem im Leistungsangebot oder bei der Bestellung ausgewählten
                        Leistungszeitraum, jedoch mindestens für sechs Monate geschlossen. Eine vorzeitige Auflösung des Vertrags
                        durch den Kunden ist nicht möglich. Kostenfreie Monate werden nicht auf die Vertragslaufzeit angerechnet,
                        falls in der Leistungsbeschreibung nicht anders vereinbart. Er verlängert sich automatisch um jeweils einen
                        weiteren Leistungszeitraum (wie bei der Bestellung vereinbart), sofern er nicht schriftlich auf dem Postweg
                        gekündigt wurde.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        Die Kündigung muss spätestens drei Wochen vor dem Ende der Vertragslaufzeit der jeweils anderen Partei
                        per Email zugehen. Einer Angabe von Gründen bedarf es für die Kündigung nicht.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Das Recht zur Sperrung und fristlosen Kündigung aus wichtigem Grund bleibt davon unberührt. Als wichtiger
                        Grund für die Kündigung des Vertrages durch TeamSystems gilt insbesondere
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        ein Verstoß des Kunden gegen gesetzliche Verbote, insbesondere die Verletzung urheberrechtlicher,
                        wettbewerbsrechtlicher, namensrechtlicher oder datenschutzrechtlicher Bestimmungen.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        die Veröffentlichung von nationalsozialistischen, rassistischen, radikalen oder in anderer Form illegalen
                        Inhalten durch den Kunden.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        ein Zahlungsverzug, der länger als zwei Wochen andauert. Hierbei wird der Restbetrag der gesamten
                        Vertragslaufzeit sofort zur Zahlung fällig.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        Zuwiderhandlung nach &sect; 5.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td colSpan={3}>
                        Kündigungen, die aufgrund der vorbenannten Verstöße durch TeamSystems ausgesprochen werden, entlasten den
                        Kunden nicht von der Erfüllung seiner gesamten Vertragslaufzeit sowie der damit verbundenen Kosten. Im Falle
                        einer fristlosen Kündigung werden dem Kunden die Kosten für die Restlaufzeit in einem Betrag in Rechnung
                        gestellt. Im Falle einer fristlosen Kündigung behält sich TeamSystems vor, über sie bezogene Domains
                        (kostenlose wie kostenpflichtige) fristlos zu kündigen.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>d.</b></td>
                    <td colSpan={3}>
                        Als wichtiger Grund für die Kündigung gilt weiterhin eine grundlegende Änderung des rechtlichen oder
                        technischen Standards im Internet, wenn es für TeamSystems dadurch unzumutbar wird, ihre Leistungen ganz
                        oder teilweise weiter zu erbringen. In diesem Fall erstattet TeamSystems anteilig die bereits vom Kunden
                        gezahlten Entgelte zurück.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>e.</b></td>
                    <td colSpan={3}>
                        Die Sperrung aller im Vertrag geschlossenen Dienstleistungen durch TeamSystems bei einem Zahlungsverzug von
                        mehr als 5 Tagen bleibt davon ebenfalls unberührt.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>

                            &sect; 7 Leistungsangebot und Allgemeine Leistungsbedingungen
                        </b>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        TeamSystems erbringt ihre Leistungen im übrigen nach Maßgabe ihrer Bestellung
                        und ihrer Allgemeinen Leistungsbedingungen und -beschreibungen (ALBB).
                        Bestellung und die Allgemeinen Leistungsbeschreibungen und bedingungen sind
                        Bestandteil dieses Vertrages und können ebenso wie die Allgemeinen Geschäftsbedingungen vor Vertragsschluss
                        ausgedruckt oder herunter geladen werden.
                    </td>
                </tr>
                <tr className="h30">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>

                        <br />
                        <br />
                        <br />
                        <br />

                        <b>ALBB der Firma TeamSystems</b>
                        <br />
                        <br />
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Stand vom 22.02.2023
                    </td>
                </tr>
                <tr className="h10">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 1 Leistungen der TeamSystems
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>


                        Leistungsbeschreibung Softwareüberlassung und Nutzungsrechte

                        TeamSystems räumt dem Kunden an der T.O.M. Software (nachfolgend Lizenz-Software genannt) das einfache,
                        räumlich und zeitlich beschränkte Recht ein, diese Leistungen im Rahmen des mit dem Kunden jeweils
                        geschlossenen Vertrages zu nutzen. Eine weitergehende Nutzung der Lizenz-Software als zuvor beschrieben ist
                        unzulässig. Dem Kunden ist insbesondere untersagt, Unterlizenzen zu erteilen, die Lizenz-Software zu
                        vervielfältigen oder in sonstiger Weise zu verwenden.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        Leistungsbeschreibung Software-Service

                        TeamSystems übernimmt die Pflege und die Wartung der in der Bestellung
                        aufgeführten Lizenz-Software. Darüber hinaus unterstützt sie den Kunden in der Anwendung der
                        Lizenz-Software.<br /><br />

                        Die Pflege umfasst:
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        die Beseitigung von Mängeln und Störungen der Lizenz-Software auch über den Gewährleistungszeitraum hinaus,
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>
                        die Pflege der Lizenz-Software im Rahmen ihrer in der Dokumentation beschriebenen Funktionen,
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>

                        die kostenlose Überlassung aktualisierter Versionen der Lizenz-Software (Updates) nebst zugehöriger
                        Online-Dokumentation,
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{width:'20px'}} valign='top'>-</td>
                    <td>

                        die Verpflichtung von TeamSystems zur Pflege der jeweils aktualisierten Programmversion.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td colSpan={3}>
                        Während der Vertragslaufzeit hat der Kunde Anspruch auf Beratung per E-Mail. Der Hilfsdienst ist jedoch kein
                        Ersatz für die Anwenderschulung und die Überlassung der Dokumentationen.

                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Soweit nicht ausdrücklich anders vereinbart, darf TeamSystems die ihr obliegenden Leistungen auch von
                        fachkundigen Mitarbeitern oder Dritten erbringen lassen.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>d.</b></td>
                    <td colSpan={3}>
                        Für Leistungen, die TeamSystems auf Wunsch des Kunden an einem anderen Ort als
                        ihrem Geschäftssitz erbringt, kann sie auch An- und Abfahrtszeiten berechnen.
                        Für jeden gefahrenen Kilometer steht ihr eine Pauschale von 0,51 EUR zu.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 2 Rechte Dritter
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Der Kunde versichert ausdrücklich, dass die Bereitstellung und Veröffentlichung
                        der Inhalte der von ihm eingestellten und/oder nach seinen Informationen für ihn
                        von TeamSystems erstellten Webseiten weder gegen deutsches noch gegen sein
                        hiervon gegebenenfalls abweichendes Heimatrecht, insbesondere Urheber-,
                        Datenschutz- und Wettbewerbsrecht, verstößt. TeamSystems behält sich vor,
                        Seiten, die inhaltlich bedenklich erscheinen, von einer Speicherung auf ihrem
                        Server auszunehmen. Den Kunden wird sie von einer etwa vorgenommenen Löschung der
                        Seiten unverzüglich informieren. Das Gleiche gilt, wenn TeamSystems von dritter
                        Seite aufgefordert wird, Inhalte auf ihren Webseiten zu ändern oder zu löschen,
                        weil sie angeblich fremde Rechte verletzen.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        TeamSystems ist berechtigt, solche Webseiten, deren Speicherung auf dem Webserver Rechte Dritter verletzen
                        könnte, von der Festplatte zu löschen oder in anderer geeigneter Weise vom Zugriff durch Dritte
                        auszuschließen. TeamSystems wird den Kunden unverzöglich von einer solchen Maßnahme benachrichtigen. Für den
                        Fall, dass der Kunde den Nachweis erbringen kann, dass eine Verletzung von Rechten Dritter nicht zu
                        befürchten ist, wird TeamSystems die betroffenen Webseiten Dritten wieder verfügbar machen. Von
                        Ersatzansprüchen Dritter, die auf unzulässigen Inhalten einer Webseite des Kunden beruhen, stellt der Kunde
                        TeamSystems hiermit frei.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        TeamSystems ist berechtigt die durch a) und b) entstandenen Kosten und
                        Aufwendungen dem Kunden zu berechnen, sofern diese mehr als 15 Minuten pro
                        Monat betragen.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 3 Internetdomains
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Soweit Gegenstand der Leistungen der TeamSystems auch die Verschaffung und/oder
                        Pflege von Internetdomains ist, wird sie gegenüber dem DENIC, dem InterNIC oder einer anderen Organisation
                        zur Domainvergabe lediglich als Vermittler tätig. Durch Verträge mit solchen Organisationen wird
                        ausschließlich der Kunde berechtigt und verpflichtet.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        TeamSystems hat auf die Domainvergabe keinen Einfluss. Sie übernimmt deshalb
                        keine Gewähr dafür, dass die für den Kunden beantragten und delegierten Domains
                        frei von Rechten Dritter oder einzigartig sind oder auf Dauer Bestand haben. Das
                        gilt auch für die unterhalb der Domain der TeamSystems vergebenen Subdomains.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Sollte der Kunde von dritter Seite aufgefordert werden, eine Internetdomain aufzugeben, weil sie angeblich
                        fremde Rechte verletzt, wird er TeamSystems hiervon unverzüglich unterrichten. TeamSystems ist in einem
                        solchen Fall berechtigt, im Namen des Kunden auf die Internetdomain zu verzichten, falls der Kunde nicht
                        sofort Sicherheit für etwaige Prozess- und Anwaltskosten in ausreichender Höhe (mindestens 10.000 EUR)
                        stellt. Von Ersatzansprüchen Dritter, die auf der unzulässigen Verwendung einer Internetdomain beruhen,
                        stellt der Kunde TeamSystems hiermit frei.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 4 Datenschutz
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Der Kunde ist damit einverstanden, dass persönliche Daten (Bestandsdaten) und
                        andere Informationen, die sein Nutzungsverhalten (Verbindungsdaten) betreffen
                        (z.B. Zeitpunkt, Anzahl und Dauer der Verbindungen, Up- und
                        Downloads), von TeamSystems während der Dauer des Vertragsverhältnisses
                        gespeichert werden, soweit dies zur Erfüllung des Vertragszwecks, insbesondere
                        für Abrechnungszwecke, erforderlich ist. Der Kunde erklärt sein Einverständnis
                        mit der Speicherung der Daten. Die erhobenen Bestandsdaten verarbeitet und nutzt TeamSystems auch zur
                        Beratung seiner Kunden, zur Werbung und zur Marktforschung für eigene Zwecke und zur bedarfsgerechten
                        Gestaltung seiner Telekommunikationsleistungen. Der Kunde kann einer solchen Nutzung seiner Daten
                        widersprechen.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        TeamSystems verpflichtet sich, dem Kunden auf Verlangen jederzeit über den
                        gespeicherten Bestand von personenbezogenen Daten, soweit er ihn betrifft, vollständig und
                        unentgeltlich Auskunft zu erteilen. TeamSystems wird weder diese Daten noch den
                        Inhalt privater Nachrichten des Kunden ohne dessen Einverständnis an Dritte
                        weiterleiten. Dies gilt nur insoweit nicht, als TeamSystems gesetzlich
                        verpflichtet ist, Dritten, insbesondere staatlichen Stellen, solche Daten zu
                        offenbaren oder soweit international anerkannte technische Normen dies vorsehen
                        und der Kunde nicht widerspricht. Der Kunde wird ebenfalls keine privaten
                        Nachrichten an Dritte weiterleiten. Dies gilt insbesondere für Informationen, die dem Kunden per Ticket,
                        Mail oder persönlich zur Verfügung gestellt werden.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        TeamSystems weist den Kunden ausdrücklich darauf hin, dass der Datenschutz für Datenübertragungen in offenen
                        Netzen wie dem Internet nach dem derzeitigen Stand der Technik nicht umfassend gewährleistet werden kann.
                        Der Kunde weiß, dass TeamSystems das auf dem Server gespeicherte Seitenangebot und unter Umständen auch
                        weitere dort abgelegte Daten des Kunden aus technischer Sicht jederzeit einsehen kann. Auch andere
                        Teilnehmer am Internet sind unter Umständen technisch in der Lage, unbefugt in die Netzsicherheit
                        einzugreifen und den Nachrichtenverkehr zu kontrollieren. Für die Sicherheit der von ihm ins Internet
                        übermittelten Daten trägt der Kunde deshalb selbst Sorge.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 5 Haftungsbeschränkung
                        </b>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Eine Haftung von TeamSystems für Schäden, die durch unberechtigte Eingriffe Dritter entstehen, ist
                        ausgeschlossen.<br />
                        Auch bei sorgfältigster und umfangreichster Prüfung kann eine absolute Fehlerfreiheit der Lizenzsoftware
                        nicht gewährleistet werden. Insofern übernimmt TeamSystems keine Haftung für die Richtigkeit der
                        Lizenzsoftware.<br />
                        TeamSystems haftet für Schäden, die von ihr oder ihren Erfüllungsgehilfen grob
                        fahrlässig oder vorsätzlich herbeigeführt wurden. Bei der Verletzung
                        wesentlicher Vertragspflichten ist die Haftung in Fällen einfacher
                        Fahrlässigkeit bei Vermögensschäden der Art nach auf vorhersehbare, unmittelbare
                        Schäden und der Höhe nach auf die Leistungen der von TeamSystems oder ihren Erfüllungsgehilfen bei der
                        jeweiligen eigenen Versicherung abgeschlossenen Haftpflichtversicherung mit einer Deckungssumme von max.
                        100.000,00 EUR beschränkt. Diese Einschränkungen gelten nicht bei Gesundheits- oder Körperschäden.

                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 6 Freistellung
                        </b>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Der Kunde verpflichtet sich, TeamSystems im Innenverhältnis von allen etwaigen Ansprüchen Dritter
                        freizustellen, die auf rechtswidrigen Handlungen des Kunden oder inhaltlichen Fehlern der von diesem zur
                        Verfügung gestellten Informationen beruhen. Dies gilt insbesondere für Urheber-, Datenschutz- und
                        Wettbewerbsrechtsverletzungen.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 7 Anwendbares Recht, Erfüllungsort, Gerichtsstand
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Der Vertrag unterliegt deutschem Recht.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        Erfüllungsort für alle Leistungspflichten, auch die der Kunden, ist Berlin, sofern und soweit sich aus der
                        Art der zu erbringenden Leistung nicht etwas anderes ergibt.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Ist der Kunde ein Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches
                        Sondervermögen, wird als ausschließlicher Gerichtsstand unser Geschäftssitz für alle Ansprüche, die sich aus
                        oder aufgrund dieses Vertrages ergeben, vereinbart. Gleiches gilt gegenüber Personen, die keinen allgemeinen
                        Gerichtsstand in Deutschland haben oder Personen, die nach Abschluss des Vertrages ihren Wohnsitz oder
                        gewöhnlichen Aufenthaltsort außerhalb von Deutschland verlegt haben oder deren Wohnsitz oder gewöhnlicher
                        Aufenthaltsort im Zeitpunkt der Klageerhebung nicht bekannt ist.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 8 Sonstiges
                        </b>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>a.</b></td>
                    <td colSpan={3}>
                        Änderungen oder Ergänzungen dieses Vertrages gelten nur dann, wenn sie schriftlich vereinbart wurden. Das
                        gilt auch für eine Änderung dieser Schriftformklausel.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>b.</b></td>
                    <td colSpan={3}>
                        Alle Erklärungen der TeamSystems können auf elektronischem Weg an den Kunden gerichtet werden. Dies gilt
                        auch für Abrechnungen im Rahmen des Vertragsverhältnisses.
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td valign='top'><b>c.</b></td>
                    <td colSpan={3}>
                        Der Kunde kann mit Forderungen gegenüber der TeamSystems nur aufrechnen, wenn sie anerkannt oder
                        rechtskräftig festgestellt sind.
                    </td>
                </tr>
                <tr className="h20">
                    <td></td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <b>
                            &sect; 9 Salvatorische Klausel
                        </b>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        Sollte eine Bestimmung dieses Vertrages unwirksam sein oder werden oder sollte der Vertrag eine
                        ausfüllungsbedürftige Lücke enthalten, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht. An
                        die Stelle der unwirksamen Bestimmung oder der Lücke tritt eine dem wirtschaftlichen Zweck der Vereinbarung
                        nahe kommende Regelung, die von den Parteien vereinbart worden wäre, wenn sie die Unwirksamkeit der
                        Bestimmung gekannt hätten.
                    </td>
                </tr>

            </table>

        </div>

    )
}