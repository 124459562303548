import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';






const scrollWithOffset = (el: any) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

export default function HeaderBar() {
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();



  const TSBackgroundColor = "#006600";
  const navigationLinkStyle = { marginLeft: "20px" };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const navigationLinks = [
    { label: "Start", link: "/#start", trackingTitle: "Home - Start" },
    { label: "Features", link: "/#features", trackingTitle: "Home - Features" },
    { label: "Demo", link: "/#demo", trackingTitle: "Home - Demo" },
    { label: "Premium", link: "/#premium", trackingTitle: "Home - Premium" },
    { label: "Login", link: "/login", trackingTitle: "Login" },
    { label: "Dokumentation", link: "/dokumentation", trackingTitle: "Documentation" },
    { label: "Support", link: "/support", trackingTitle: "Support" },
    { label: "Blog", link: "/blog", trackingTitle: "Blog" },
  ];

  const navigationComponents : any = navigationLinks.map(element => {
   return (<HashLink
   key={element.label}
      scroll={el => {
        scrollWithOffset(el); trackPageView({
          documentTitle: element.trackingTitle

        })
      }}
      to={element.link}
      color="#ffffff"
      style={navigationLinkStyle}
      smooth
    >{element.label}</HashLink>);
  });

  const burgerMenuItems = navigationLinks.map(element => {
    return (
      // <MenuItem onClick={ () => {handleClose(); navigate(element.link);  }}>{element.label}</MenuItem>
      <MenuItem onClick={handleClose}
      key={element.label}
      style={{backgroundColor: '#006600'}}>
        <HashLink
        scroll={el => {
          scrollWithOffset(el); trackPageView({
            documentTitle: element.trackingTitle

          })
        }}
        to={element.link}
        color="#006600"
        smooth
      >{element.label}</HashLink></MenuItem>

    )
  });

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: TSBackgroundColor }}
    >




      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div><Button variant="contained" color="success" style={{ margin: '24px' }} onClick={() => {
          navigate("/kostenlos-starten")
        }} >Jetzt kostenlos starten</Button></div>

        <div id="burger_menu">
          <IconButton
            onClick={handleClick}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            
          
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{right: '0px'}}
          >
            {burgerMenuItems}
          
          </Menu>
        </div>

        <Toolbar style={{ justifyContent: "end" }} id="header_link_bar">

          {navigationComponents}

        
        </Toolbar>
      </div>
    </AppBar>
  );
}