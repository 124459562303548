import React from 'react';
import { FeatureBox } from "./FeatureBox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import EmailIcon from "@mui/icons-material/Email";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import BarChartIcon from "@mui/icons-material/BarChart";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";


export default function Features() {

    const featureIconStyle = { fontSize: "40px" };


    return (
    <Container sx={{ py: 8 }} maxWidth="lg">
        <section id="features">
          <Typography
          className="feature_headline"
            variant="h2"
            sx={{ fontSize: '10vw', textAlign: "center", fontWeight: 500, marginBottom: "60px" }}
          >
            Unsere kostenlosen Features
          </Typography>

          <Grid container spacing={3}>
            <FeatureBox
              icon={<MoreTimeIcon sx={featureIconStyle} />}
              title="Termine verwalten"
            >
              Intuitive Verwaltung von Einzelterminen und Trainings. Zusagen,
              Deadline für Zu- und Absagen, uvm...
            </FeatureBox>
            <FeatureBox
              icon={<BarChartIcon sx={featureIconStyle} />}
              title="Anwesenheits-Statistik"
            >
              Detaillierte Anwesenheitsstatistik für Trainings und
              Einzeltermine. Inklusive Zuverlässigkeitsdaten, ob jemand zu- oder
              abgesagt hat
            </FeatureBox>
            <FeatureBox
              icon={<SyncLockIcon sx={featureIconStyle} />}
              title="Berechtigungen"
            >
              Frei definierbare Rollen. Konfigurierbare Berechtigungen für
              Rollen und/oder Einzelpersonen
            </FeatureBox>
            <FeatureBox
              icon={<SecurityIcon sx={featureIconStyle} />}
              title="Datenschutz"
            >
              100% DSGVO konform. Server-Standort ist ausschließlich
              Deutschland. Keine Weitergabe an Daten an Externe.
            </FeatureBox>
            <FeatureBox
              icon={<CreditCardOffIcon sx={featureIconStyle} />}
              title="Dauerhaft kostenlos"
            >
              Dauerhaft kostenlos für bis zu drei Mannschaften im Verein.
            </FeatureBox>
            <FeatureBox
              icon={<HelpOutlineIcon sx={featureIconStyle} />}
              title="Kostenloser Email-Support"
            >
              Kostenloser Email-Support für alle Nutzerinnen und Nutzer
            </FeatureBox>
            <FeatureBox
              icon={<RemoveShoppingCartIcon sx={featureIconStyle} />}
              title="Komplett Werbefrei"
            >
              Unser System ist komplett werbefrei. Sowohl im Browser, als auch
              in der App auf dem Smartphone
            </FeatureBox>
            <FeatureBox
              icon={<PhoneAndroidIcon sx={featureIconStyle} />}
              title="Kostenlose App"
            >
              Kostenlose Apps für iOS und Android. Ständige Weiterentwicklung
              des Systems
            </FeatureBox>
            <FeatureBox
              icon={<FlightTakeoffIcon sx={featureIconStyle} />}
              title="Abwesenheiten"
            >
              Abwesenheiten während Urlaub, Verletzung oder aus anderen Gründen
              sorgen für automatische Absagen
            </FeatureBox>
            <FeatureBox
              icon={<ScheduleSendIcon sx={featureIconStyle} />}
              title="Saisons verwalten"
            >
              Nur Termine der aktuellen Saison sehen. Saisons können
              abgeschlossen und später auch wieder fortgesetzt werden
            </FeatureBox>
            <FeatureBox
              icon={<CalendarMonthIcon sx={featureIconStyle} />}
              title="Kalender synchronisieren"
            >
              Synchronisiere Deinen Kalender automatisch mit Deinem Smartphone
              oder jeder anderen Anwendung (z.B. Outlook)
            </FeatureBox>
            <FeatureBox
              icon={<ColorLensIcon sx={featureIconStyle} />}
              title="Vereinsfarben"
            >
              Eigenes Logo und Nutzung der Vereinsfarben in der Web-Version
            </FeatureBox>
            <FeatureBox
              icon={<EmailIcon sx={featureIconStyle} />}
              title="Emails versenden"
            >
              Versende Emails je nach Berechtigungen an Deine Mannschaft oder
              den gesamten Verein
            </FeatureBox>
            <FeatureBox
              icon={<PlaylistAddCheckIcon sx={featureIconStyle} />}
              title="Teilnahme-Beschränkung"
            >
              Limit für Zusagen bei Terminen. Auflistung wer wann zu- oder
              abgesagt hat für jeden einzelnen Termin
            </FeatureBox>
            <FeatureBox
              icon={<FamilyRestroomIcon sx={featureIconStyle} />}
              title="Familien-Login"
            >
              Ein Login für alle Familienmitglieder. Schnelle Übersicht über
              alle Termine von Kindern und Geschwistern
            </FeatureBox>
            <FeatureBox
              icon={<LockIcon sx={featureIconStyle} />}
              title="Sicherheit"
            >
              Sämtliche Daten werden verschlüsselt übertragen. Unsere Server
              sind mit dem aktuellen Stand der Technik gesichert.
            </FeatureBox>
            <FeatureBox
              icon={<NewspaperIcon sx={featureIconStyle} />}
              title="Neuigkeiten"
            >
              News-Funktion für Neuigkeiten innerhalb des Teams oder innerhalb
              des Vereins
            </FeatureBox>
            <FeatureBox
              icon={<MoreHorizIcon sx={featureIconStyle} />}
              title="... und vieles mehr"
            >
              Außerdem entwickeln wir TeamSystems ständig weiter. Fehlt etwas?
              Schreib uns an support@teamsystems.de
            </FeatureBox>
          </Grid>
        </section>
      </Container>
    )
}