import React from "react";
import Container from "@mui/material/Container";
import logo from "../images/teamsystems-logo-transparent-black-green-257x120.png";
import SignUpFormStep2 from "./SignUpFormStep2";
import { useMatomo } from '@m4tt72/matomo-tracker-react'


export default function SignUpStep2() {

  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = "Kostenlos starten - Schritt 2";
    trackPageView();
    trackEvent({
      category: "Signup",
      action: "step2-email-confirmed"
    });
  }, [trackPageView, trackEvent ]);


  return (
    <Container maxWidth="xs" sx={{ textAlign: "center" }} id="signup">
      <img src={logo} alt="TeamSystems Logo" style={{marginTop: "30px"}} />
     

      {/* <Typography variant="h2">Kostenlos starten</Typography> */}
     

      <SignUpFormStep2  />
    </Container>
  );
}
