
import {gql} from '@apollo/client';


const LOGIN_MUTATION = gql`mutation login( $input: LoginInput!) { 
  login( input: $input) {
      ok
      errors
      token                                    
  }
}`;

  export default LOGIN_MUTATION;