import React, { SyntheticEvent, useState, useCallback } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormError from "./FormError";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY from "../queries/IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY";
import INITIALIZE_ORGANIZATION_MUTATION from "../mutations/INITIALIZE_ORGANIZATION_MUTATION";
import { useSetSnackbar } from "../hooks/useSnackbar";
import { useMatomo } from '@m4tt72/matomo-tracker-react'


interface IFormInput {
  organizationName: string;
  domainIdentifier: string;
  email: string;
}

export default function SignUpForm() {

  const { trackEvent } = useMatomo();



  const setSnackbar : any = useSetSnackbar();


  const [
    isDomainIdentifierModifiedManually,
    setIsDomainIdentifierModifiedManually,
  ] = useState(false);

  const [
    search,
    { loading: domainIdentifierLoading, data: domainIdentifierData },
  ] = useLazyQuery(IS_DOMAIN_IDENTIFIER_AVAILABLE_QUERY);


  const [initializeOrganization, { 
    loading: initializeOrganizationLoading
     }] = useMutation(INITIALIZE_ORGANIZATION_MUTATION);

// eslint-disable-next-line
  const debouncedDomainIdentifierAvailabilityCheck = useCallback(
    _.debounce(search, 1000),
    [_.debounce, search]
  );

  const navigate = useNavigate();


  const schema = getValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: { organizationName: "", domainIdentifier: "", email: "" },
    resolver: yupResolver(schema),
  });

  const handleOrganizationNameChange = (e: SyntheticEvent, onChange: any) => {
    onChange(e);

    if (watch("organizationName") === "") {
      setValue("domainIdentifier", "");
      setIsDomainIdentifierModifiedManually(false);
    }

    if (!isDomainIdentifierModifiedManually) {
      setValue(
        "domainIdentifier",
        convertNameToDomainIdentifier(watch("organizationName"))
      );
    }
  };

  const handleDomainIdentifierChange = (e: any, onChange: any) => {
    setIsDomainIdentifierModifiedManually(true);
    e.target.value = e.target.value.toLowerCase();
    onChange(e);

    debouncedDomainIdentifierAvailabilityCheck({
      variables: { domainIdentifier: watch("domainIdentifier") },
    });
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {

    


    const searchResult = await search({ variables: { domainIdentifier: data.domainIdentifier } });




    if (domainIdentifierLoading || searchResult.data.isDomainIdentifierAvailable.ok === false) {
      console.error("Submit currently not available due to invalid domainIdentifier");

      
      setSnackbar("Es ist leider ein Fehler aufgetreten", "error", 2000 );
      return;
    }


    const mutationResult = await initializeOrganization({
      variables: {
        input: {
          organizationName: data.organizationName,
          domainIdentifier: data.domainIdentifier,
          email: data.email
        }
      }
    });


    if (mutationResult.data?.initializeOrganization?.ok === true ) {

      
      setSnackbar("Fast fertig. Bitte bestätige jetzt Deine Email Adresse", "success" );

      navigate("/start-check-email");
    } else {

      const errorMessage = mutationResult.data?.initializeOrganization?.errors ? mutationResult.data?.initializeOrganization?.errors.join(". ") : "Es sind unerwartete Fehler aufgetreten";
      setSnackbar(errorMessage, "error" );

    }



  };

  const domainIdentifierFeedbackAvailable =
    !domainIdentifierLoading && domainIdentifierData !== undefined;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="organizationName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Vereinsname"
                inputProps={{ "data-testid": "input_organizationName" }}
                onChange={(e) =>
                  handleOrganizationNameChange(e, field.onChange)
                }
                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.organizationName && (
            <FormError testid="error_input_organizationName">
              Bitte gib einen Vereinsnamen von mindestens 3 Zeichen an.
            </FormError>
          )}
        </Grid>


        <Grid item xs={12}>
          <Controller
            name="domainIdentifier"
            control={control}
            render={({ field }) => (
              <TextField

                {...field}

                size="small"
                value={watch("domainIdentifier")}
                onChange={(e) =>
                  handleDomainIdentifierChange(e, field.onChange)
                }
                label="Gewünschte Internetadresse für die Browser-Version"
                inputProps={{ "data-testid": "input_domainIdentifier" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">https://</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      .teamsystems.de
                    </InputAdornment>
                  ),
                }}
                style={{ width: "100%", backgroundColor:'white' }}

              />
            )}
          />
          {errors.domainIdentifier && (
            <FormError testid="error_input_domainIdentifier" style={{ textAlign: "left" }}>
              Bitte gib ein Internetadresse von mindestens 3 Zeichen an, nur die Zeichen a-z (nur Kleinschreibung), 0-9 und Bindestrich und es muss mit einem Buchstaben beginnen.
            </FormError>
          )}



          {domainIdentifierFeedbackAvailable &&
            domainIdentifierData.isDomainIdentifierAvailable.ok === false && (
              <Alert severity="error" >
                {domainIdentifierData.isDomainIdentifierAvailable.errors[0].error}
              </Alert>
            )}

          {domainIdentifierFeedbackAvailable &&
            domainIdentifierData.isDomainIdentifierAvailable.ok === true && (
              <Alert severity="success" >
                Die gewünschte Internetadresse ist verfügbar
              </Alert>
            )}
        </Grid>



        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="email"
                size="small"
                label="Deine Email Adresse"
                inputProps={{ "data-testid": "input_email" }}
                style={{ width: "100%", backgroundColor:'white' }}
              />
            )}
          />
          {errors.email && (
            <FormError testid="error_input_email">
              Bitte gib eine gültige Email Adresse an.
            </FormError>
          )}
        </Grid>

        <Grid item xs={12} style={{ alignItems: "center" }}>

          {!initializeOrganizationLoading &&
            <Button
              variant="contained"
              size="large"
              type="submit"
              data-testid="step1_submit_button"
              sx={{ backgroundColor: "#006600", color: "#ffffff" }}
              onClick={() => {
                trackEvent({
                  category: "Signup",
                  action: "step1-submit"
                });
                handleSubmit(onSubmit);
              }}
            >
              Abschicken
            </Button>
          }

          {initializeOrganizationLoading &&
            <CircularProgress />
          }
        </Grid>
      </Grid>

    </form>
  );
}

export const convertNameToDomainIdentifier = (name: string): string => {
  let result = name.toLowerCase();
  result = result.replace(/ /g, "-");
  result = result.replace(/[^a-z0-9-]/g, "");
  result = result.replace(/^[^[a-z]*]*/g, "");
  return result;
};

export const getValidationSchema = () => {
  const yupValidation: any = {
    organizationName: yup.string().required().trim().min(2).max(100),
    domainIdentifier: yup
      .string()
      .required()
      .trim()
      .matches(/^[a-z][a-z0-9-]{2,}$/),
    email: yup.string().required().email(),
  };

  return yup.object().shape(yupValidation).required();
};
