import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  StartNowButton
  
} from "./TeamSystemsComponents";

import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PricingCard = ({
  pricePerMonth,
  maxTeamString,
}: {
  pricePerMonth: number;
  maxTeamString: string;
}) => {
  return (
    <Card variant="outlined" >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 50,
              fontWeight: 500,
              marginBottom: "0px",
              lineHeight: "1em",
            }}
            color="text.secondary"
            gutterBottom
          >
            {pricePerMonth} €
          </Typography>
          <Typography
            sx={{ fontSize: 15, fontWeight: 500, marginBottom: "0px" }}
            color="text.secondary"
            gutterBottom
          >
            / Monat
          </Typography>
        </div>

        <Typography sx={{ fontSize: 10, fontWeight: 400, marginTop: "30px" }}>
          Anzahl an Mannschaften
        </Typography>
        <Typography sx={{ fontSize: 20, fontWeight: 200 }}>
          {maxTeamString}
        </Typography>

        <Container maxWidth="xl" sx={{ textAlign: "left" }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "30px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>
              Alle genannten Features
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "15px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>Werbefrei</Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center", marginTop: "15px" }}
          >
            <CheckCircleOutlineIcon color="success" />
            <Typography sx={{ fontSize: 12 }}>
              Serverstandort Deutschland
            </Typography>
          </Stack>
        </Container>
      </CardContent>
    </Card>
  );
};

const PremiumInformation = () => {
  return (
    <section id="premium">
      <Container maxWidth="sm" sx={{ textAlign: "center" }}>
        TeamSystems ist <b>dauerhaft kostenlos</b> solange Ihr nicht mehr als
        drei Mannschaften aus Eurem Verein über das System pflegt. Ein Wechsel
        zwischen den Produkten ist jederzeit möglich.
      </Container>
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "30px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <PricingCard
                pricePerMonth={0}
                maxTeamString="max. 3 Mannschaften"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={3}>
              <PricingCard
                pricePerMonth={10}
                maxTeamString="max. 6 Mannschaften"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={3}>
              <PricingCard
                pricePerMonth={20}
                maxTeamString="max. 9 Mannschaften"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={3}>
              <PricingCard
                pricePerMonth={30}
                maxTeamString="kein Mannschaftslimit"
              />
            </Grid>
          </Grid>
        </div>
      </Container>

      <Container maxWidth="md" sx={{ textAlign: "center", marginTop: "30px" }}>
        <StartNowButton />
      </Container>
    </section>
  );
};

export default PremiumInformation;
