import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import logo from "../images/teamsystems-logo-transparent-black-green-257x120.png";
import { useMatomo } from '@m4tt72/matomo-tracker-react'



export default function SignUp() {

  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {
    document.title = "TeamSystems Anmeldung - Email überprüfen"
    trackPageView();
    trackEvent({
      category: "Signup",
      action: "step1-check-email"
    })
  }, [trackPageView, trackEvent]);


  return (
    <Container maxWidth="sm" sx={{ textAlign: "center" }}>
      <img src={logo} alt="TeamSystems Logo" style={{marginTop: "30px"}} />
      <Typography
        variant="h4"
        id="email_confirmation_text"
        sx={{
          textAlign: "center",
          fontWeight: 500,
          marginBottom: "20px",
          marginTop: "40px",
        }}
      >
        Wir haben Dir eine Email geschickt um Deine Email-Adresse zu bestätigen. Bitte klicke auf den Link in der Email.
      </Typography>

     
    </Container>
  );
}
