import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SignUpForm from "./SignUpForm";
import logo from "../images/teamsystems-logo-transparent-black-green-257x120.png";
import { useMatomo } from '@m4tt72/matomo-tracker-react'



export default function SignUp() {

  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = "Kostenlos starten - Schritt 1";
    trackPageView();
    trackEvent({
      category: "Signup",
      action: "step1-initial"
    });
  }, [trackPageView, trackEvent ]);




  return (
    <Container maxWidth="sm" sx={{ textAlign: "center" }} id="signup">
      <img src={logo} alt="TeamSystems Logo" style={{marginTop: "30px"}} />
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: 500,
          marginBottom: "20px",
          marginTop: "40px",
        }}
      >
        Kostenlos starten
      </Typography>

      {/* <Typography variant="h2">Kostenlos starten</Typography> */}
      <Typography sx={{marginBottom: "20px"}} variant="h6">In nur 60 Sekunden
      
      </Typography>

      

      <SignUpForm />
    </Container>
  );
}
