import React, { useEffect } from 'react';
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import { Link } from '@mui/material';


export default function Privacy() {

    const {trackPageView} = useMatomo();
    useEffect( () => {
        document.title = 'Datenschutzerklärung';
        trackPageView();
    })

    return (
        <div>
            <div style={{ padding: '20px' }}>
             <h2>Datenschutzerklärung</h2>

                Die Datenschutz-Grundverordnung trat am 25.Mai 2016 europaweit in Kraft. Die Übergangsphase endet am 25.Mai 2018.
                Teamsystems sieht sich als Auftragsverarbeiter im Auftrag des Vereins/der Organisation. Zusätzlich hat jedoch auch jeder User, der seine Daten selbst einträgt die volle Kontrolle über seine Daten.

                <h3>Änderung des Betreibers zum 1.10.2024</h3>

                Zum 1.10.2024 ändert sich der Betreiber von TeamSystems. Der neue Betreiber ist die Zeitner GmbH. Für den neuen Betreiber werden die folgenden Datenschutzrichtlinien gelten: <Link style={{color: 'black'}} href="/datenschutz-zeitner">hier</Link>

                <br />
                <br />

                <h4>Informationen für Endanwender</h4>

                <p>Gemäß DSGVO hat jede Person innerhalb der EU Anrecht auf Einsicht, Änderung und Löschung seiner Daten. Bitte wenden Sie sich hierfür mit einer Email 
                    an <a style={{color: 'black'}} href="mailto:datenschutz@teamsystems.de">datenschutz@teamsystems.de</a></p>

                <h4>Informationen für Vereinsverantwortliche</h4>

                <p>Sobald Sie oder Ihre Mitarbeiter personenbezogene Daten in unser System eintragen, handelt es sich um eine Auftragsverarbeitung nach <a style={{color: 'black'}} href="https://dsgvo-gesetz.de/art-28-dsgvo/" rel="nofollow">Art. 28 der DS-GVO</a>.</p>

                <p>Unter Personenbezogenen Daten versteht man "alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (betroffene Person) beziehen". Als Beispiele
                    können hier Vorname, Nachname, Anschrift, Telefonnummern, Email Adressen, Geschlecht, oder IP Adressen aufgeführt werden.</p>

                <h5>Vertrag zur Auftragsdatenverarbeitung</h5>

                Administratoren können im Menü unter <b>Allgemein</b> <>&rarr;</> <b>DSGVO</b> eine Auftragsverarbeitungs-Vereinbarung mit uns elektronisch abschließen. Dort können Sie sich ein Vorschau
                einer solchen Vereinbarung ansehen und diese dann durch anklicken einer Checkbox und abschicken des Formulars abschließen. Anschließend können Sie es sich herunterladen, ausdrucken und zu Ihren
                Datenschutzunterlagen ablegen.







                <br />
                <h4>Grundsätzliches</h4>

                Der Datenschutz ist bei Teamsystems ein wichtiges Anliegen. Es werden nur jene Daten gespeichert, die zur Erbringung und Verbesserung des Dienstes notwendig sind.Die hier aufgelisteten Datenschutzbestimmungen ergänzen die Allgemeinen Geschäftsbedingungen von Teamsystems.
                Grundsätzlich gilt:
                <br />
                <ul>

                    <li>Teamsystems unterwirft sich bei der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten den
                        gesetzlichen Vorschriften der Bundesrepublik Deutschland und der Europäischen Union (DSGVO)</li>
                    <li>Ihre personenbezogenen Daten sind nur für die Mitglieder Ihrer Mannschaft sichtbar.</li>
                    <li>Sie haben jederzeit die Möglichkeit eine Auflistung und/oder Löschung der über sie gespeicherten Daten zu erhalten.</li>
                </ul>


                <h4>Gespeicherte Daten</h4>

                <h5>Stammdaten</h5>
                Um die Dienste von Teamsystems nutzen zu können, muss mindestens eine Email Adresse angegeben werden. Anschließend
                können die folgenden Stammdaten ausgefüllt werden:
                <ul>
                    <li>Geschlecht</li>
                    <li>Vorname</li>
                    <li>Nachname</li>
                    <li>Wohnanschrift bestehend aus Straße + Hausnummer, Postleitzahl und Ort</li>
                    <li>Geburtstag</li>
                    <li>beliebig viele Mobilfunknummern, Email-Adressen und Festnetznummern</li>
                    <li>selbstgewählter Benutzername und Passwort</li>
                    <li>Profilbild</li>

                </ul>
                <br />
                Bei den Email Adressen, Mobilfunknummern , Festnetznummern, Vorname, Nachname, Wohnanschrift und Geburtsdatum ist es möglich, für jeden Eintrag festzulegen, ob dieser in der Mannschaftsliste auftauchen darf.
                Darf er dies nicht, so haben nur die Mitglieder des Trainerstabs des eigenen Teams Zugriff auf diese Daten. Darüber hinaus sind diese Daten für niemanden sichtbar.
                <br />
                <h5>Termindaten</h5>
                <br />
                Über die Stammdaten hinaus werden Informationen gespeichert, wenn der User zu einem Termin eingeladen wird, wenn er
                dafür seine Teilnahme mit einem der drei Stati "zugesagt", "weiß nicht" oder "abgesagt" signalisiert und für die
                Termine eine Nachricht hinterlässt.<br />
                <br />
                Darüber hinaus können Mitglieder des Trainerstabs des eigenen Teams festhalten, ob die Person bei einem Termin anwesend war oder nicht. Daraus werden
                dann Statistiken wie Trainings- und Terminbeteiligungen erstellt.
                <br />

                <h4>Cookies</h4>
                <br />
                Teamsystems speichert nach Ihrer Anmeldung (mit Benutzername und Passwort) Cookies, die Sie für die Dauer Ihres Besuches identifizieren. Ein Cookie ist eine kleine
                Textdatei die auf Ihrem Computer gespeichert wird. Der Inhalt wird bei jedem Request - ohne dass sie dieses manuell initiieren müssen - übertragen. Nach dem Ende der Sitzung
                verfällt das Cookie automatisch. Eine Sitzung wird automatisch beendet, wenn der User 30 Minuten nicht aktiv war. <br />
                Wählt der User die Option "Eingeloggt bleiben" aus, so verfällt die Sitzung nicht automatisch. Dies sollten sie nicht auswählen, wenn Sie den Rechner mit mehreren Personen teilen.
                Eine Sitzung kann aktiv beendet werden, in dem rechts oben je nach Situation des Users auf den Link "Logout" geklickt wird oder das selbige in einer Drop-Down Maske ausgewählt wird.

                <h4>Log Dateien</h4>
                <br />
                Wir erheben und speichern automatisch in so genannten Server Log Files Informationen, die Ihr Internet-Browser (über einen PC oder ein mobiles Endgerät) an uns übermittelt. Dies sind unter anderem:
                <br />
                <ul>
                    <li>Browsertyp/ -version</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Referrer URL (die zuvor besuchte Seite)</li>
                    <li>Hostname des zugreifenden Rechners (IP Adresse)</li>
                    <li>Datum und Uhrzeit der Serveranfrage</li>
                    <li>Meldung, ob der Abruf erfolgreich war</li>
                    <li>Name der abgerufenen Datei</li>
                    <li>Menge der gesendeten Daten.</li>
                </ul><br />
                Eine Zuordnung dieser Daten zu einer bestimmten Person ist uns nicht möglich. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Diese Daten werden zu statistischen Zwecken, um die Qualität dieser Webseite und der Services dieser Webseite zu verbessern, ausgewertet und im Anschluss gelöscht.
                <br />
                <br />
                Teamsystems nutzt für statistische Auswertungen das Programm "Matomo", welches ausschließlich auf von Teamsystems betriebenen Servern installiert ist. Eine Datenübermittlung an externe Dienste (z.B. "Google Analytics" oder "Alexa") findet nicht statt.

                <h4>Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</h4>
                <br />
                Um bestimmte Funktionen zu erfüllen werden persönliche Daten auch an externe Dienstleister und Serviceanbieter weitergegeben. Dies erfolgt immer unter dem Gebot der Datenminimierung, das heißt es werden nur solche Daten weitergegeben, die für die Funktion absolut notwendig sind. 
                Eine Liste aller Anbieter findest Du <Link style={{color: 'black'}} href="/datenschutz-anbieter-verzeichnis">hier</Link>
              

                <h4>Abrufbarkeit der Datenschutzbestimmungen</h4>
                <br />
                Sie können diese Bestimmungen von jeder Seite über den Link "Datenschutz" aus abrufen.

            </div>

        </div>
    )
}