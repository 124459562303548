import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SignUpForm from "./SignUpForm";
import logo from "../images/teamsystems-logo-transparent-black-green-257x120.png";
import { useMatomo } from '@m4tt72/matomo-tracker-react'
import LoginForm from "./LoginForm";



export default function Login() {

  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = "Login";
    trackPageView();
    trackEvent({
      category: "Login",
      action: "login"
    });
  }, [trackPageView, trackEvent ]);




  return (
    <Container maxWidth="sm" sx={{ textAlign: "center" }} id="signup">
      <img src={logo} alt="TeamSystems Logo" style={{marginTop: "30px"}} />
     

      

      <LoginForm />
    </Container>
  );
}
