import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import { DocumentationSection, DocumentationContent } from '@teamsystems/ts-types/types/general';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import GET_BLOG_ENTRIES_QUERY from '../queries/GET_BLOG_ENTRIES_QUERY';
import { useMatomo } from '@m4tt72/matomo-tracker-react'


interface BlogEntryType {
  publishedAt: Date;
  headline: string;
  content: string;
  image: string;
  slug: string;
}



export default function BlogOverview() {


  const { contentSlug } = useParams();


  const { trackPageView, trackEvent } = useMatomo();

  React.useEffect(() => {

    document.title = contentSlug ? contentSlug : "Blog";
    trackPageView();
    trackEvent({
      category: "Blog",
      action: contentSlug ? "article" : "index"
    });
  }, [trackPageView, trackEvent, contentSlug ]);

  const { loading, data, error } = useQuery(GET_BLOG_ENTRIES_QUERY);
  if (!data || loading) {
    return <div>Lade ... </div>;
  }


  const blogEntries = data.getBlogEntries.blogEntries;

  // const blogEntries = [
  //   {
  //     createdAt: new Date("2023-03-10"),
  //     headline: "Trainer*innen verbringen wöchentlich sieben Stunden mit der Team-Organisation",
  //     content: `<p>Trainer in Sportvereinen haben viele Aufgaben, die über das eigentliche Training hinausgehen. Ein großer Teil ihrer Zeit wird für die Organisation ihrer Mannschaft verwendet. In diesem Blog-Artikel wollen wir uns mit der Frage beschäftigen, wie viel Zeit Trainer in Sportvereinen mit der Organisation ihrer Mannschaft verbringen.</p>
  //     <p>Die Organisation einer Sportmannschaft beinhaltet viele verschiedene Aufgaben, wie zum Beispiel die Planung von Trainingszeiten und -orten, die Vorbereitung von Spielplänen und Turnieren, die Kommunikation mit Spielern und Eltern sowie die Koordination von Ausrüstung und Materialien. Diese Aufgaben erfordern oft viel Zeit und Mühe, die von Trainern erbracht werden müssen.</p>
  //     <p>Eine Studie des Deutschen Olympischen Sportbundes hat ergeben, dass Trainer in Deutschland durchschnittlich 7 Stunden pro Woche mit der Organisation ihrer Mannschaft verbringen. Dies entspricht etwa einem Viertel ihrer Gesamtzeit, die sie für die Betreuung ihrer Mannschaft aufwenden.</p>
  //     <p>Eine wichtige Rolle bei der Organisation einer Mannschaft spielt auch die Kommunikation. Trainer müssen oft mit Spielern, Eltern und anderen Trainern kommunizieren, um sicherzustellen, dass alle Informationen und Anweisungen richtig verstanden werden. Dazu gehört auch die Organisation von Team-Meetings und das Versenden von E-Mails oder Textnachrichten.</p>
  //     <p>Insgesamt ist die Organisation einer Sportmannschaft ein wichtiger Bestandteil der Arbeit von Trainern in Sportvereinen. Die Zeit, die sie damit verbringen, hängt jedoch von verschiedenen Faktoren ab, wie der Größe der Mannschaft, dem Trainings- und Spielplan sowie der Art des Sports. Es ist jedoch wichtig zu beachten, dass eine gute Organisation dazu beitragen kann, dass die Mannschaft erfolgreicher ist und dass die Spielerinnen und Spieler sich besser auf das Training und die Spiele konzentrieren können.</p>
  //     <p>TeamSystems hilft diese Organisations-Zeit zu minimieren damit Trainer*innen und Betreuer*innen auf das eigentliche konzentrieren können. Teste TeamSystems für Deinen Verein gleich heute noch. Komplett kostenlos und unverbindlich.</p>
  //     `,
  //     slug: "trainerinnen-verbringen-wochentlich-sieben-stunden-mit-der-team-organisation",
  //     image: "communication.png"
  //   },
  //   {
  //     createdAt: new Date("2023-03-05"),
  //     headline: "Die Entwicklung des Ehrenamts im Sportverein in Zahlen",
  //     content: `<p>Der Sport in Deutschland ist ein wichtiger Bestandteil der Gesellschaft und wird oft durch ehrenamtliche Arbeit in Sportvereinen unterstützt. Ehrenamtliche Arbeit spielt eine wichtige Rolle für die Entwicklung und das Wachstum des Sports in Deutschland. Aber wie viele Menschen sind eigentlich ehrenamtlich in Sportvereinen tätig und wie hat sich diese Zahl in den letzten Jahren verändert, insbesondere über Corona?</p>
  //     <p>Laut einer Studie des Bundesministeriums für Familie, Senioren, Frauen und Jugend gab es im Jahr 2020 in Deutschland insgesamt 30,4 Millionen Menschen, die sich ehrenamtlich engagieren. Davon waren etwa 5,8 Millionen Menschen in Sportvereinen aktiv. Dies entspricht etwa 19% der ehrenamtlich tätigen Bevölkerung in Deutschland.</p>
  //     <p>Allerdings haben sich die Zahlen in den letzten Jahren verändert. Im Jahr 2015 waren es noch 6,2 Millionen Menschen, die Msich in Sportvereinen engagierten. Dies bedeutet einen Rückgang von etwa 6,5% in fünf Jahren. Einige Experten vermuten, dass diese Veränderung auf einen Wandel in der Gesellschaft zurückzuführen ist, bei dem sich immer mehr Menschen für eine individuelle Sportausübung entscheiden.</p>
  //     <p>Die Corona-Pandemie hat ebenfalls Auswirkungen auf die Zahl der Ehrenamtlichen in Sportvereinen gehabt. Einige Vereine mussten ihre Aktivitäten aufgrund der Einschränkungen einstellen, was zu einem Rückgang der ehrenamtlichen Tätigkeit führte. Allerdings haben viele Vereine auch digitale Angebote geschaffen, um den Kontakt zu ihren Mitgliedern aufrechtzuerhalten und weiterhin sportliche Aktivitäten anzubieten.</p>
  //     <p>Insgesamt zeigt sich, dass ehrenamtliche Arbeit in Sportvereinen ein wichtiger Bestandteil des Sports in Deutschland ist. Obwohl die Zahl der Engagierten in den letzten Jahren zurückgegangen ist und die Corona-Pandemie einige Herausforderungen mit sich gebracht hat, ist die Arbeit der Ehrenamtlichen unverzichtbar für die Entwicklung und das Wachstum des Sports. Daher sollten Vereine und die Gesellschaft als Ganzes weiterhin das Engagement in Sportvereinen fördern und unterstützen.</p>
  //     <p>Um ehrenamtlich im Verein zu helfen, muss man keine Trainerlizenz haben. Wer den Trainerinnen und Betreuern bei der Organistion der Mannschaft hilft, leistet einen großen Beitrag zu einem funktionierenden Verein. TeamSystems unterstützt Euch und Euren Verein dabei. Probiert es einfach aus.</p>
  //     `,
  //     slug: "die-entwicklung-des-ehrenamts-im-sportverein-in-zahlen",
  //     image: "volunteer.png"

  //   },
  //   {
  //     createdAt: new Date("2023-02-26"),
  //     headline: "Neue Mitglieder für den Verein gewinnen",
  //     content: `<p>Die Corona-Pandemie hat auch den Sportvereinen in Deutschland schwer zu schaffen gemacht. Viele Mitglieder haben während des Lockdowns ihre Mitgliedschaft pausiert oder gekündigt, da sie nicht mehr am Vereinssport teilnehmen konnten. Nun, da die Maßnahmen langsam gelockert werden, stellt sich die Frage, wie die Vereine neue Mitglieder gewinnen und die alten Mitglieder zurückgewinnen können.

  //     <p><b>1. Online-Präsenz ausbauen</b><br/>
  //     In Zeiten von Social Distancing und Lockdowns hat sich gezeigt, dass eine starke Online-Präsenz für Sportvereine unverzichtbar ist. Um neue Mitglieder zu gewinnen, sollten Vereine ihre Online-Präsenz weiter ausbauen und ihre Aktivitäten auf Social-Media-Plattformen wie Instagram oder Facebook bewerben. Vereine können auch virtuelle Angebote wie Online-Kurse oder Trainingsvideos anbieten, um Interesse bei potenziellen Mitgliedern zu wecken.</p>
      
  //     <p><b>2. Schnupperangebote und Probetrainings</b><br/>
  //     Um neue Mitglieder zu gewinnen, können Sportvereine Schnupperangebote oder Probetrainings anbieten. So können Interessierte den Verein und seine Angebote kennenlernen, bevor sie sich für eine Mitgliedschaft entscheiden. Vereine sollten hierbei flexibel sein und verschiedene Angebote bereitstellen, um möglichst viele Interessenten zu erreichen.</p>
      
  //     <p><b>3. Mitglieder werben Mitglieder</b><br/>
  //     Eine gute Möglichkeit, neue Mitglieder zu gewinnen, ist das Werben von bestehenden Mitgliedern. Vereine können Anreize wie Rabatte oder Prämien für Mitglieder bieten, die erfolgreich neue Mitglieder werben. Auch das Teilen von Erfahrungen und Erfolgsgeschichten auf Social-Media-Plattformen oder in Vereinsnewslettern kann helfen, neue Mitglieder zu gewinnen.</p>
      
  //     <p><b>4. Veranstaltungen und Events</b><br/>
  //     Veranstaltungen und Events bieten eine gute Möglichkeit, um Aufmerksamkeit für den Verein zu generieren und potenzielle Mitglieder zu erreichen. Sportvereine können beispielsweise offene Tage veranstalten oder an lokalen Sportevents teilnehmen, um den Verein und seine Aktivitäten zu präsentieren.</p>
      
  //     <p><b>5. Angebote für alle Altersgruppen</b><br/>
  //     Sportvereine sollten ein breites Angebot an Aktivitäten für alle Altersgruppen bereithalten, um möglichst viele potenzielle Mitglieder zu erreichen. Vereine sollten hierbei auf die Bedürfnisse und Interessen ihrer Zielgruppe eingehen und auch Trends wie Yoga oder Functional Training aufgreifen.</p>
      
  //     <p>Insgesamt gibt es viele Möglichkeiten für Sportvereine, um nach der Corona-Pandemie neue Mitglieder zu gewinnen und alte Mitglieder zurückzugewinnen. Eine starke Online-Präsenz, Schnupperangebote, Mitglieder-werben-Mitglieder-Programme, Veranstaltungen und ein breites Angebot an Aktivitäten sind dabei wichtige Erfolgsfaktoren. Wichtig ist es, flexibel zu bleiben und auf die Bedürfnisse und Interessen der Zielgruppe einzugehen, um erfolgreich neue Mitglieder zu gewinnen.</p>`,
  //     slug: "neue-mitglieder-fur-den-verein-gewinnen",
  //     image: "membership_development.jpg"
  //   },
  //   {
  //     createdAt: new Date("2023-02-24"),
  //     headline: "Wie kann TeamSystems ehrenamtliche im Sportverein bei der täglichen Arbeit unterstützen?",
  //     content: `TeamSystems ist ein innovatives Tool, das Trainern und Betreuern in Sportvereinen dabei hilft, ihre tägliche Arbeit effizienter zu gestalten. In diesem Blog-Artikel wollen wir uns mit den Vorteilen von TeamSystems für Trainer und Betreuer in ihrer täglichen Arbeit beschäftigen.

  //     <p><b>1.Effiziente Planung</b>
  //     TeamSystems ermöglicht es Trainern und Betreuern, den Trainingsplan und Spielplan ihrer Mannschaft einfach und schnell zu erstellen und zu organisieren. Das Tool bietet eine intuitive Oberfläche, die es den Nutzern ermöglicht, ihre Planung mit wenigen Klicks zu erstellen und zu verwalten. Durch die automatische Aktualisierung der Planung können Trainer und Betreuer sicherstellen, dass alle Spieler und Eltern stets auf dem neuesten Stand sind.</p>
      
  //     <p><b>2. Einfache Kommunikation</b>
  //     TeamSystems bietet eine einfache und schnelle Kommunikationsplattform für Trainer und Betreuer. Das Tool ermöglicht es, Nachrichten an einzelne Spieler, Gruppen oder die gesamte Mannschaft zu senden. Trainer können Informationen über Trainings, Spiele oder andere relevante Ereignisse schnell und effektiv an ihre Mannschaft weitergeben. Durch die zentrale Kommunikationsplattform können alle Spieler und Eltern sicherstellen, dass sie nichts Wichtiges verpassen.</p>
      
  //     <p><b>3. Übersichtliche Organisation</b>
  //     TeamSystems bietet eine übersichtliche und leicht zugängliche Plattform, auf der Trainer und Betreuer alle relevanten Informationen ihrer Mannschaft organisieren können. Spielerdaten, Spiel- und Trainingspläne, Mannschaftslisten und vieles mehr können zentral gespeichert und abgerufen werden. So haben Trainer und Betreuer jederzeit einen umfassenden Überblick über ihre Mannschaft und können schnell und einfach auf wichtige Informationen zugreifen.</p>
      
  //     <p><b>4. Bessere Teamleistung</b>
  //     Durch die effiziente Planung und Organisation der Mannschaft kann TeamSystems dazu beitragen, die Leistung des Teams insgesamt zu verbessern. Durch die bessere Kommunikation können die Spieler besser auf das Training und die Spiele vorbereitet werden, was zu einer verbesserten Leistung führen kann. Darüber hinaus kann TeamSystems auch dazu beitragen, den Teamgeist zu stärken, indem es allen Spielern und Eltern eine transparente und übersichtliche Plattform bietet, auf der sie sich über ihre Mannschaft informieren können.</p>
      
  //     <p>Insgesamt bietet TeamSystems Trainer und Betreuern in Sportvereinen viele Vorteile, die ihre tägliche Arbeit effizienter und erfolgreicher machen können. Das Tool ist einfach zu bedienen, übersichtlich und bietet eine zentrale Plattform für die Planung, Organisation und Kommunikation mit der Mannschaft.</p>`,
  //     slug: "wie-kann-teamsystems-ehrenamtliche-im-sportverein-bei-der-taglichen-arbeit-unterstutzen",
  //     image: "teamsystems-logo-small.png"
  //   },
    
  //   {
  //     createdAt: new Date("2023-02-19"),
  //     headline: "Aufwärmübungen für Kinder",
  //     content: `<p>Vor jedem Training kommt das Aufwärmen. Wir stellen Euch heute 20 Übungen vor, die man egal in welcher Sportart gut als Alternative zum klassischen Rundenlaufen machen kann:</p>
      
  //     <p><b>1.Lauf-ABC</b><br/> 
  //     Beginne mit dem klassischen Lauf-ABC, um die motorischen Fähigkeiten der Kinder zu verbessern. Dabei werden verschiedene Lauftechniken wie Anfersen, Kniehebellauf und Seitwärtslaufen trainiert. Diese Übung kann zu Beginn jeder Sportstunde durchgeführt werden.</p>

  //     <p><b> 2. Hampelmann</b><br/>  Der Hampelmann ist eine einfache und effektive Aufwärmübung für Kinder. Dabei werden die Arme und Beine abwechselnd geöffnet und geschlossen, um die Muskeln zu dehnen und den Kreislauf in Schwung zu bringen. Die Kinder können dabei auch rhythmische Bewegungen ausprobieren.</p>
      
  //     <p><b> 3.Dribbel-Parcours</b><br/>
  //     Ein Dribbel-Parcours eignet sich besonders für Ballsportarten wie Fußball, Basketball oder Handball. Dabei müssen die Kinder den Ball durch verschiedene Hindernisse manövrieren und dabei ihre Koordination und Geschicklichkeit verbessern.</p>
      
  //     <p><b> 4.Koordinationsleiter</b><br/> 
  //      Die Koordinationsleiter ist ein klassisches Tool für das Koordinationstraining. Dabei müssen die Kinder durch die Leiter springen oder mit den Füßen die Leiter berühren, um ihre Beweglichkeit und Schnelligkeit zu verbessern. Diese Übung kann auch für andere Sportarten wie Leichtathletik oder Tennis angepasst werden.</p>
      
  //     <p><b> 5.Kreisspiele</b><br/>  Kreisspiele sind eine lustige Möglichkeit, um die Kinder aufzuwärmen und gleichzeitig ihre sozialen Fähigkeiten zu fördern. Dabei können verschiedene Spiele wie "Wer hat Angst vorm schwarzen Mann?" oder "Hase und Jäger" gespielt werden, die den Kindern Spaß machen und den Körper aufwärmen.</p>
  //     <p><b> 6.Seilspringen</b><br/>  Seilspringen ist eine einfache und effektive Übung, die den ganzen Körper aufwärmt und die Ausdauer verbessert. Die Kinder können dabei verschiedene Techniken wie einfaches Springen, Doppelschritte oder Kreuzsprünge ausprobieren.</p>

  //     <p><b> 7.Dehnübungen</b><br/>  Dehnübungen sind eine wichtige Ergänzung zum Aufwärmen, da sie die Muskeln vor Verletzungen schützen und die Flexibilität verbessern. Dabei können verschiedene Dehnübungen für Beine, Arme, Schultern und Rücken durchgeführt werden.</p>

  //     <p><b> 8.Fangspiele</b><br/>  Fangspiele sind eine weitere lustige Möglichkeit, um die Kinder aufzuwärmen und ihre Ausdauer und Koordination zu verbessern. Dabei können verschiedene Varianten wie "Fangen mit Berührung", "Fangen mit Ball" oder "Fangen mit Schwanz" gespielt werden.</p>

  //     <p><b> 9.Partnerübungen</b><br/>  Partnerübungen sind eine gute Möglichkeit, um die Kinder aufeinander abzustimmen und ihre sozialen Fähigkeiten zu verbessern. Dabei können verschiedene Übungen wie Partnerplank, Partnerkniebeugen oder Partnerstretching durchgeführt werden.</p>

  //     <p><b> 10.Hindernisparcours</b><br/>  Ein Hindernisparcours ist eine weitere Möglichkeit, um die Kinder aufzuwärmen und ihre Koordination und Geschicklichkeit zu verbessern. Dabei können verschiedene Hindernisse wie Hütchen, Reifen, Kästen oder Matten aufgestellt werden, die die Kinder überwinden müssen.</p>
      
      
  //     <p><b> 11.Tanz-Challenge</b><br/>  Eine Tanz-Challenge ist eine spaßige Möglichkeit, um die Kinder aufzuwärmen und gleichzeitig ihre Beweglichkeit, Koordination und Ausdauer zu verbessern. Dabei können verschiedene Choreografien und Musikstile ausprobiert werden.</p>

  //     <p><b> 12.Ballprellen</b><br/>  Ballprellen ist eine einfache und effektive Übung, um den Körper aufzuwärmen und die Hand-Augen-Koordination zu verbessern. Dabei kann der Ball gegen eine Wand, den Boden oder mit einem Partner geprellt werden.</p>

  //     <p><b> 13.Gleichgewichtsübungen</b><br/>  Gleichgewichtsübungen sind eine wichtige Ergänzung zum Aufwärmen, da sie die Körperhaltung verbessern und die Verletzungsgefahr reduzieren. Dabei können verschiedene Übungen wie Einbeinstand, Balance-Board oder Slackline ausprobiert werden.</p>

  //     <p><b> 14.Hampelmänner mit Variationen</b><br/>  Hampelmänner können durch verschiedene Variationen wie seitliche Sprünge, Kniebeugen oder Sprungvarianten abgewandelt werden, um den Körper aufzuwärmen und die Muskeln zu dehnen.</p>

  //     <p><b> 15.Schrittfolgen</b><br/>  Schrittfolgen sind eine weitere Möglichkeit, um die Kinder aufzuwärmen und ihre Beweglichkeit und Koordination zu verbessern. Dabei können verschiedene Schrittfolgen wie Shuffle-Steps, Grapevine-Steps oder V-Steps ausprobiert werden.</p>

  //     <p><b> 16.Sprungübungen</b><br/>  Sprungübungen sind eine effektive Möglichkeit, um den Körper aufzuwärmen und die Sprungkraft zu verbessern. Dabei können verschiedene Sprungübungen wie Ausfallsprünge, Kniebeuge-Jumps oder Box-Jumps durchgeführt werden.</p>

  //     <p><b> 17.Yoga-Übungen</b><br/>  Yoga-Übungen sind eine tolle Ergänzung zum Aufwärmen, da sie den Körper dehnen, kräftigen und entspannen. Dabei können verschiedene Yoga-Übungen wie der herabschauende Hund, die Kobra oder die Kriegerpose ausprobiert werden.</p>

  //     <p><b> 18.Laufspiele</b><br/>  Laufspiele sind eine spaßige Möglichkeit, um die Kinder aufzuwärmen und ihre Ausdauer und Koordination zu verbessern. Dabei können verschiedene Spiele wie Staffellauf, Verfolgungsjagd oder Hindernislauf gespielt werden.</p>

  //     <p><b> 19.Plyometrische Übungen</b><br/>  Plyometrische Übungen sind eine effektive Möglichkeit, um den Körper aufzuwärmen und die Explosivkraft zu verbessern. Dabei können verschiedene plyometrische Übungen wie Sprungkniebeugen, Sprungausfallschritte oder Burpees durchgeführt werden.</p>

  //     <p><b> 20.Seilziehen</b><br/> 
  //      Seilziehen ist eine spaßige und effektive Möglichkeit, um den Körper aufzuwärmen und die Kraft und Ausdauer zu verbessern. Dabei können die Kinder in Teams gegeneinander antreten und das Seil ziehen.</p>`,
  //     slug: "aufwarmubungen-fur-kinder",
  //     image: "warmup.jpg"
  //   },

  //   {
  //     createdAt: new Date("2023-02-10"),
  //     headline: "Aufwandsentschädigungen versteuern?",
  //     content: `<p>Als Trainerin oder Trainer kann man für seine Tätigkeit eine Aufwandsentschädigung erhalten, die steuerpflichtig ist. Doch es gibt auch die Möglichkeit, die sogenannte Übungsleiterpauschale in Anspruch zu nehmen. In diesem Blog-Artikel erklären wir, was die Übungsleiterpauschale ist, wer sie in Anspruch nehmen kann und wie sie steuerlich behandelt wird.

  //     </p><p>Die Übungsleiterpauschale ist eine steuerliche Vergünstigung, die es Personen ermöglicht, bis zu einer bestimmten Grenze Einkünfte aus einer nebenberuflichen Tätigkeit steuerfrei zu verdienen. Diese Grenze liegt aktuell bei 3.000 Euro pro Jahr. Das bedeutet, dass man als Trainerin oder Trainer bis zu diesem Betrag im Jahr steuerfrei verdienen kann.
      
  //     </p><p>Um die Übungsleiterpauschale in Anspruch nehmen zu können, müssen bestimmte Voraussetzungen erfüllt sein. Zum einen muss es sich um eine nebenberufliche Tätigkeit handeln, die in der Regel nicht mehr als 15 Stunden pro Woche umfasst. Zum anderen muss es sich um eine pädagogische oder sportliche Tätigkeit handeln. Hierzu zählen zum Beispiel das Trainieren von Sportmannschaften oder das Unterrichten von Musikinstrumenten.
      
  //     </p><p>Wichtig ist auch, dass die Aufwandsentschädigung nicht als Entlohnung für eine bereits bestehende Arbeitsstelle gezahlt wird. Es muss sich um eine zusätzliche Tätigkeit handeln, die nebenberuflich ausgeübt wird.
      
  //     </p><p>Steuerlich betrachtet ist die Übungsleiterpauschale eine Freigrenze. Das bedeutet, dass alle Einkünfte, die man als Trainerin oder Trainer aus der entsprechenden Tätigkeit erzielt, bis zu einem Betrag von 3.000 Euro im Jahr steuerfrei sind. Erzielt man Einkünfte über dieser Grenze, sind diese Einkünfte insgesamt steuerpflichtig.
      
  //     </p><p>Es ist allerdings wichtig zu beachten, dass die Übungsleiterpauschale nicht mit anderen steuerlichen Vergünstigungen kombiniert werden kann. Das bedeutet, dass man als Trainerin oder Trainer entweder die Übungsleiterpauschale oder die steuerliche Absetzbarkeit von Ausgaben wie Fahrtkosten oder Arbeitsmitteln in Anspruch nehmen kann. Eine Kombination beider Möglichkeiten ist nicht möglich.
      
  //     </p><p>Um die Übungsleiterpauschale in Anspruch zu nehmen, muss man dies in der jährlichen Steuererklärung angeben. Hierfür gibt es verschiedene Formulare, je nach Art der Tätigkeit und Höhe der Einkünfte. Als Trainerin oder Trainer ist in der Regel die Anlage Übungsleiter der Einkommensteuererklärung relevant. Dort können die steuerfreien Einkünfte aus der Tätigkeit angegeben werden.
      
  //     </p><p>Zusammenfassend lässt sich sagen, dass die Übungsleiterpauschale eine interessante Möglichkeit für Trainerinnen und Trainer ist, um steuerliche Vergünstigungen in Anspruch zu nehmen. Es ist allerdings wichtig zu beachten, dass die Voraussetzungen für die Inanspruchnahme der Übungsleiterpauschale erfüllt sein müssen und dass die Pauschale nicht mit anderen steuerlichen Vergünstigungen kombiniert werden kann. Es empfielt sich im Zweifel eine genaue Recherche oder der Gang zum Steuerberater.</p>`,
  //     slug: "aufwandsentschadigungen-versteuern",
  //     image: "taxes.jpg"
  //   },
  //   {
  //     createdAt: new Date("2023-02-05"),
  //     headline: "Die Struktur einer Trainingseinheit",
  //     content: `<p>Eine gut strukturierte Trainingseinheit im Mannschaftssport ist ein wichtiger Bestandteil für eine erfolgreiche Saison. Die folgende Anleitung beschreibt, wie eine klassische Trainingseinheit im Mannschaftssport aufgebaut sein sollte.

  //     </p><p><b>Aufwärmen (10-15 Minuten)</b>
      
  //     Das Aufwärmen ist ein wichtiger Teil der Trainingseinheit und sollte nicht vernachlässigt werden. Es dient dazu, die Muskeln und Gelenke auf die bevorstehende Belastung vorzubereiten und das Verletzungsrisiko zu minimieren. Hierbei empfiehlt es sich, verschiedene Lauf- und Dehnübungen sowie Koordinations- und Reaktionsübungen durchzuführen.
      
  //     </p><p><b>Technik- und Taktiktraining (30-45 Minuten)</b>
      
  //     Im Anschluss an das Aufwärmen sollten die technischen und taktischen Fähigkeiten der Spieler verbessert werden. Hierbei sollten die Übungen auf die speziellen Anforderungen des Mannschaftssports abgestimmt sein. Dazu gehören beispielsweise Pass- und Schussübungen, Dribbling- und Ballkontrollübungen sowie Spielformen, bei denen die Taktik trainiert wird.
      
  //     </p><p><b>Konditionstraining (15-20 Minuten)</b>
      
  //     Um die körperliche Leistungsfähigkeit der Spieler zu verbessern, sollten auch konditionelle Aspekte in das Training eingebunden werden. Hierbei können unterschiedliche Trainingsformen wie Intervallläufe, Sprint- und Ausdauertraining oder Zirkeltraining durchgeführt werden.
      
  //     </p><p><b>Abschlussspiel (20-30 Minuten)</b>
      
  //     Das Abschlussspiel ist ein wichtiger Teil der Trainingseinheit, da es die Möglichkeit bietet, das Gelernte in einer realen Spielsituation anzuwenden. Hierbei sollten die Spieler motiviert werden, das Erlernte umzusetzen und gezielt Fehler zu korrigieren. Auch die Kommunikation untereinander sollte in diesem Spiel gefördert werden.
      
  //     </p><p><b>Cool-Down (10 Minuten)</b>
      
  //     Nach der intensiven Belastung sollten die Muskeln wieder langsam zur Ruhe kommen. Hierbei empfiehlt es sich, verschiedene Dehnübungen durchzuführen, um die Muskeln zu entspannen und die Regeneration zu fördern.
      
  //     </p><p><b>Zusammenfassung</b>
      
  //     Eine klassische Trainingseinheit im Mannschaftssport sollte aus einem Aufwärmprogramm, Technik- und Taktiktraining, Konditionstraining, einem Abschlussspiel und einem Cool-Down bestehen. Dabei ist es wichtig, die Übungen auf die speziellen Anforderungen des jeweiligen Sports abzustimmen und die Spieler gezielt zu motivieren. Durch eine gut strukturierte Trainingseinheit kann die Leistungsfähigkeit der Spieler verbessert und die Erfolgsaussichten der Mannschaft gesteigert werden.</p>`,
  //     slug: "die-struktur-einer-trainingseinheit",
  //     image: "training_unit.jpg"
  //   },

  //   {
  //     createdAt: new Date("2023-01-31"),
  //     headline: "Die häufigsten Sportverletzungen und was man dagegen tun kann",
  //     content: `<p>Sportverletzungen können jeden Sportler treffen und haben oft einen großen Einfluss auf das Training und den Erfolg im Sport. Es gibt verschiedene Arten von Sportverletzungen, die von leichten Verstauchungen bis hin zu schweren Knochenbrüchen reichen können. In diesem Artikel werden die 10 häufigsten Sportverletzungen und Tipps zur Soforthilfe beschrieben.

  //     </p><p><b>Verstauchungen</b></br>
  //     Verstauchungen treten auf, wenn ein Gelenk durch eine Überdehnung der Bänder überlastet wird. Soforthilfe besteht darin, das betroffene Gelenk zu kühlen und zu ruhen. Eine Schmerzbehandlung mit Eis und Kompression (z.B. mit einer elastischen Binde) kann ebenfalls helfen.
      
  //     </p><p><b>Zerrungen</b></br>
  //     Zerrungen treten auf, wenn eine Muskel- oder Sehnenfaser überdehnt oder gerissen wird. Soforthilfe besteht darin, das betroffene Gebiet mit Eis zu kühlen, ruhen und mit Kompression zu behandeln.
      
  //     </p><p><b>Muskelkrämpfe</b></br>
  //     Muskelkrämpfe entstehen durch eine plötzliche Verhärtung der Muskeln. Soforthilfe besteht darin, den betroffenen Muskel zu dehnen und zu massieren. Trinken von Wasser oder isotonischen Getränken kann ebenfalls helfen.
      
  //     </p><p><b>Prellungen</b></br>
  //     Prellungen sind Schäden an den Weichteilen und Blutgefäßen, die durch direkte Gewalteinwirkung entstehen. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen.
      
  //     </p><p><b>Achillessehnenentzündung</b></br>
  //     Eine Achillessehnenentzündung ist eine Entzündung der Sehne, die den Unterschenkelmuskel mit der Ferse verbindet. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen. Streckübungen und Massagen können ebenfalls helfen.
      
  //     </p><p><b>Knieschmerzen</b></br>
  //     Knieschmerzen können durch Überbelastung, Fehlstellungen oder Verletzungen entstehen. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen. Streckübungen und Massagen können ebenfalls helfen.
      
  //     </p><p><b>Tennisarm</b></br>
  //     Ein Tennisarm ist eine Überlastung der Sehnen am Ellenbogen, die durch die ständige Wiederholung bestimmter Bewegungen entstehen kann. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen. Eine spezielle Bandage oder eine Unterarmmanschette kann ebenfalls helfen.
      
  //     </p><p><b>Schienbeinschmerzen</b></br>
  //     Schienbeinschmerzen können durch Überbelastung oder Verletzungen entstehen. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen. Dehnübungen und Massagen können ebenfalls helfen.
      
  //     </p><p><b>Rückenschmerzen</b></br>
  //     Rückenschmerzen können durch Überbelastung, Fehlhaltungen oder Verletzungen entstehen. Soforthilfe besteht darin, das betroffene Gebiet zu kühlen und zu ruhen. Massagen und Dehnübungen können ebenfalls helfen.</p>`,
  //     slug: "die-haufigsten-sportverletzungen-und-was-man-dagegen-tun-kann",
  //     image: "sport_injuries.jpg"
  //   },

  //   {
  //     createdAt: new Date("2023-01-25"),
  //     headline: "10 motivierende und inspirierende Zitate von Sporlerinnen und Sportlern - Teil 3",
  //     content: `<p>Der vorerst letzte Teil unserer Reihe mit Zitaten von großen - zumeist sportlichen - Persönlichkeiten</p>
  //     <ol>
  //     <li><q>Ich habe versucht, perfekt zu sein, aber ich habe erkannt, dass Perfektion nicht existiert. Es ist eine Illusion und eine Belastung.</q> - Kobe Bryant</li>
  //     <li><q>Erfolg ist kein Zufall. Es ist harte Arbeit, Ausdauer, Lernen, Opferbereitschaft und vor allem, Liebe zu dem, was man tut.</q> - Pele</li>
  //     <li><q>Sport gibt deinem Leben etwas, das nichts anderes geben kann.</q> - Federer</li>
  //     <li><q>Du musst das Spiel sehen, bevor es geschieht.</q> - Michael Jordan</li>
  //     <li><q>Es ist nicht wichtig, ob du gewinnst oder verlierst, es ist wie du das Spiel spielst.</q> - Grantland Rice</li>
  //     <li><q>Der Sport lehrt uns, immer wieder aufzustehen, uns nicht unterkriegen zu lassen und weiterzumachen.</q> - Kristina Vogel</li>
  //     <li><q>Wenn du etwas wirklich willst, wirst du einen Weg finden. Wenn nicht, wirst du eine Ausrede finden.</q> - Jim Rohn</li>
  //     <li><q>Meine Eltern haben mich gelehrt, dass es keine Grenzen gibt. Du musst nur hart arbeiten und an dich glauben.</q> - Usain Bolt</li>
  //     <li><q>Das Geheimnis meines Erfolgs? Es ist einfach: Hart arbeiten, positiv bleiben und niemals aufgeben.</q> - Dwayne 'The Rock' Johnson</li>
  //     <li><q>Im Leben geht es nicht darum, der Beste zu sein. Es geht darum, besser zu sein als gestern.</q> - Brett Favre</li>
  //   </ol>`,
  //     slug: "10-motivierende-und-inspirierende-zitate-von-sporlerinnen-und-sportlern-teil-3",
  //     image: "motivational_quotes-3.jpg"
  //   },
  //   {
  //     createdAt: new Date("2023-01-14"),
  //     headline: "Welche Bedeutung hat das Stretching im regulären Training?",
  //     content: `

  //     <p>Stretching, also das Dehnen der Muskulatur, ist ein wichtiger Bestandteil des regulären Trainings im Mannschaftssport. Es kann dabei helfen, Verletzungen vorzubeugen, die Beweglichkeit zu verbessern und die Leistungsfähigkeit zu steigern. Allerdings ist es als Trainer auch wichtig, auf einige Dinge zu achten, um das Stretching effektiv und sicher zu gestalten.</p>
      
  //     <b>Warum ist Stretching wichtig?</b>
      
  //     <p>Stretching hilft dabei, die Muskeln aufzuwärmen und zu lockern, was Verletzungen verhindern kann. Außerdem kann es die Beweglichkeit und Flexibilität verbessern, was wiederum die Leistungsfähigkeit steigert. Durch das Dehnen werden auch die Muskeln gestärkt, was langfristig zu einer besseren Körperhaltung führen kann.</p>
      
  //     <b>Worauf sollte man beim Stretching achten?</b>
      
  //     <p>Als Trainer ist es wichtig, das Stretching in das reguläre Training zu integrieren und auf einige Dinge zu achten:</p>
      
  //     <ul>
  //       <li><strong>Aufwärmen:</strong> Bevor mit dem Stretching begonnen wird, sollten sich die Spieler aufwärmen. Eine kurze Runde joggen oder einige Minuten Fahrradfahren sind hierfür geeignet.</li>
  //       <li><strong>Individuelle Bedürfnisse:</strong> Jeder Spieler hat individuelle Bedürfnisse und sollte seine Muskeln entsprechend dehnen. Als Trainer sollte man daher auf die Bedürfnisse jedes Spielers eingehen und ggf. individuelle Dehnübungen empfehlen.</li>
  //       <li><strong>Sicherheit:</strong> Beim Stretching sollte immer auf die Sicherheit geachtet werden. Die Spieler sollten darauf hingewiesen werden, dass sie nicht über ihre Schmerzgrenze hinausdehnen sollen, um Verletzungen zu vermeiden.</li>
  //       <li><strong>Variation:</strong> Um Langeweile zu vermeiden, sollte das Stretching regelmäßig variiert werden. Es gibt eine Vielzahl von Dehnübungen, die sich für das reguläre Training eignen.</li>
  //     </ul>
      
  //     <b>Fazit</b>
      
  //     <p>Stretching ist ein wichtiger Bestandteil des regulären Trainings im Mannschaftssport. Es kann dabei helfen, Verletzungen vorzubeugen, die Beweglichkeit zu verbessern und die Leistungsfähigkeit zu steigern. Als Trainer ist es wichtig, auf die individuellen Bedürfnisse der Spieler einzugehen und beim Stretching auf Sicherheit zu achten.</p>
  //     `,
  //     slug: "welche-bedeutung-hat-das-stretching-im-regularen-training",
  //     image: "stretching.jpg"
  //   },
  
  //   {
  //     createdAt: new Date("2022-12-20"),
  //     headline: "10 motivierende und inspirierende Zitate von Sporlerinnen und Sportlern - Teil 2",
  //     content: `<p>Nach dem positiven Feedback unseres ersten Teils an Zitaten im November, kommt jetzt der zweite Teil:<ol>
  //     <li><q>Ich glaube nicht an Glück. Ich glaube an Vorbereitung.</q> - Bobby Knight</li>
  //     <li><q>Erfolg ist kein Ziel, sondern ein Ergebnis von harter Arbeit und Entschlossenheit.</q> - Vida Blue</li>
  //     <li><q>Es geht darum, im Moment zu leben. Nicht morgen. Nicht gestern. Nur heute.</q> - Ryan Lochte</li>
  //     <li><q>Ich spiele nicht gegen ein Team, ich spiele gegen die Idee zu verlieren.</q> - Eric Cantona</li>
  //     <li><q>Sportler müssen einander helfen. Gegenseitige Unterstützung ist der Schlüssel zum Erfolg.</q> - Bill Russell</li>
  //     <li><q>Hartnäckigkeit und Entschlossenheit sind alles im Leben. Ohne sie kann man nichts erreichen.</q> - Martina Navratilova</li>
  //     <li><q>Man kann eine Niederlage akzeptieren, ohne besiegt zu sein.</q> - Martina Navratilova</li>
  //     <li><q>Ich habe immer geglaubt, dass ich besser sein könnte. Ich habe es nie akzeptiert, dass ich nicht gut genug sein könnte.</q> - Tiger Woods</li>
  //     <li><q>Wenn du alles gibst, was du hast, was du geben kannst, ist das alles, was zählt.</q> - Jerry Rice</li>
  //     <li><q>Wenn du ein Champion sein willst, musst du bereit sein, härter zu arbeiten als jeder andere.</q> - Muhammad Ali</li>
  //   </ol>`,
  //     slug: "10-motivierende-und-inspirierende-zitate-von-sporlerinnen-und-sportlern-teil-2",
  //     image: "motivational_quotes-2.jpg"
  //   },

  //   {
  //     createdAt: new Date("2022-12-13"),
  //     headline: "'Game-Based-Coching' oder Spielbasiertes Coaching",
  //     content: `<p>Eine erfolgreiche Coaching-Methode im Mannschaftssport ist die "Game-Based-Coaching" oder auch "Spielbasiertes Coaching". Diese Methode basiert darauf, dass der Trainer die Spieler durch gezielte Beobachtung und Analyse des Spiels dazu anregt, selbst Lösungen für Probleme auf dem Spielfeld zu finden. Dabei werden gezielte Fragen und Impulse eingesetzt, um das Denken und Handeln der Spieler zu fördern und somit langfristige Verhaltensänderungen zu erreichen.

  //     </p><p>Im Gegensatz zu traditionellen Trainingsmethoden, bei denen der Trainer die Spieler in der Regel direkt instruiert, steht bei der Game-Based-Coaching-Methode das Spiel im Vordergrund. Der Trainer fungiert dabei als Beobachter und Impulsgeber, der die Spieler durch gezielte Fragen und Feedback dazu anregt, selbst Lösungen zu finden und somit ihr Spielverständnis und ihre Handlungskompetenz zu verbessern.
      
  //     </p><p>Die Vorteile dieser Methode liegen auf der Hand: Durch die aktive Einbindung der Spieler und das gezielte Feedback werden diese motiviert und fühlen sich wertgeschätzt. Gleichzeitig werden ihre Fähigkeiten und ihr Verständnis für das Spiel verbessert, was sich langfristig auf ihre Leistung auswirkt. Darüber hinaus wird die Zusammenarbeit und Kommunikation im Team gefördert, da die Spieler gemeinsam nach Lösungen suchen und somit auch voneinander lernen.
      
  //     </p><p>Ein Beispiel für die Anwendung von Game-Based-Coaching im Mannschaftssport könnte folgendermaßen aussehen: Der Trainer beobachtet das Spiel und erkennt, dass die Mannschaft Schwierigkeiten hat, das Spiel zu kontrollieren. Anstatt den Spielern direkt Anweisungen zu geben, stellt er gezielte Fragen wie "Wie können wir das Spiel kontrollieren?" oder "Wie können wir den Ballbesitz verbessern?". Die Spieler werden somit dazu angeregt, selbst Lösungen zu finden und ihre Handlungskompetenz zu verbessern. Der Trainer gibt dabei gezieltes Feedback und unterstützt die Spieler bei der Umsetzung ihrer Ideen.
      
  //     </p><p>Insgesamt kann die Game-Based-Coaching-Methode im Mannschaftssport dazu beitragen, die Spieler zu motivieren, ihre Fähigkeiten zu verbessern und langfristige Verhaltensänderungen zu erreichen. Gleichzeitig wird die Zusammenarbeit im Team gefördert und die Spieler lernen voneinander.</p>`,
  //     slug: "game-based-coching-oder-spielbasiertes-coaching",
  //     image: "baseball-gf731d70d0_1280.jpg"
  //   },
  //   {
  //     createdAt: new Date("2022-12-08"),
  //     headline: "Warum Trinken beim Sport die Leistungsfähigkeit steigert?",
  //     content: `<b>Warum Trinken beim Sport so wichtig ist</b>
  //     <p>Während des Sports verliert der Körper viel Flüssigkeit durch Schweiß. Wenn der Körper dehydriert ist, kann dies zu einer Reduzierung der Leistungsfähigkeit führen. Der Körper benötigt Wasser, um optimal zu funktionieren. Es ist wichtig, den Flüssigkeitsverlust während des Sports auszugleichen, um die Leistungsfähigkeit aufrechtzuerhalten.</p>
      
  //     <b>Worauf du als Trainer achten musst</b>
  //     <p>Als Trainer ist es wichtig, dass du deine Spieler auf die Bedeutung von Trinken während des Sports aufmerksam machst. Hier sind einige Dinge, auf die du achten solltest:</p>
  //     <ul>
  //       <li>Sorge dafür, dass deine Spieler genügend Wasser zur Verfügung haben.</li>
  //       <li>Erkläre deinen Spielern, wie wichtig es ist, regelmäßig zu trinken und wie viel sie trinken sollten.</li>
  //       <li>Ermutige deine Spieler, regelmäßige Trinkpausen einzulegen, um den Flüssigkeitsverlust auszugleichen.</li>
  //       <li>Vermeide übermäßigen Konsum von koffeinhaltigen oder alkoholischen Getränken, da diese den Körper dehydrieren können.</li>
  //       <li>Überwache deine Spieler und achte darauf, dass sie regelmäßig trinken.</li>
  //     </ul>
      
  //     <b>Wie viel sollte man trinken?</b>
  //     <p>Die Menge an Flüssigkeit, die man während des Sports trinken sollte, hängt von verschiedenen Faktoren ab, wie z.B. der Intensität des Trainings, der Umgebungstemperatur und der individuellen körperlichen Verfassung. Als Faustregel gilt jedoch, dass man alle 15-20 Minuten etwa 150-250 ml Wasser trinken sollte.</p>
      
  //     <b>Fazit</b>
  //     <p>Das Trinkverhalten hat einen großen Einfluss auf die Leistungsfähigkeit beim Sport. Als Trainer solltest du deine Spieler immer wieder auf die Bedeutung von Trinken während des Sports hinweisen und dafür sorgen, dass ausreichend Wasser zur Verfügung steht. Indem du auf das richtige Trinkverhalten achtest, kannst du dazu beitragen, dass deine Spieler ihre volle Leistungsfähigkeit entfalten können.</p>
  //     `,
  //     slug: "warum-trinken-beim-sport-die-leistungsfahigkeit-steigert",
  //     image: "drinking.jpg"
  //   },
  //   {
  //     createdAt: new Date("2022-12-02"),
  //     headline: "Inklusion im Sportverein",
  //     content: `  <p>Der Sportverein als Ort der Begegnung und Integration spielt eine wichtige Rolle in unserer Gesellschaft. Doch leider ist es noch immer so, dass viele Menschen aufgrund ihrer körperlichen, geistigen oder psychischen Einschränkungen ausgeschlossen werden. Inklusion im Sportverein bedeutet, dass alle Menschen, unabhängig von ihrer Herkunft, Religion oder Einschränkungen, die Möglichkeit haben, am Sport teilzunehmen.</p>
  //     <b>Warum ist Inklusion im Sportverein wichtig?</b>
  //     <p>Der Sportverein bietet nicht nur die Möglichkeit, körperlich aktiv zu sein, sondern fördert auch die soziale Interaktion und stärkt das Selbstbewusstsein. Menschen mit Einschränkungen haben oft Schwierigkeiten, in der Gesellschaft akzeptiert und integriert zu werden. Der Sportverein kann hierbei helfen und ein Ort der Begegnung und des Austauschs sein.</p>
  //     <b>Wie kann ein Sportverein inklusiv gestaltet werden?</b>
  //     <p>Ein inklusiver Sportverein sollte barrierefrei gestaltet sein und die Bedürfnisse aller Mitglieder berücksichtigen. Hierzu gehören beispielsweise die Anpassung von Trainings- und Wettkampfbedingungen sowie die Bereitstellung von Hilfsmitteln. Es ist auch wichtig, ein Bewusstsein für die Bedürfnisse und Herausforderungen von Menschen mit Einschränkungen zu schaffen und Vorurteile abzubauen.</p>
  //     <b>Beispiele für inklusive Sportangebote</b>
  //     <ul>
  //       <li>Rollstuhlbasketball</li>
  //       <li>Blindentennis</li>
  //       <li>Integrative Schwimmkurse</li>
  //       <li>Laufgruppen für Menschen mit psychischen Erkrankungen</li>
  //       <li>Behindertensportgruppen</li>
  //     </ul>
  //     <b>Fazit</b>
  //     <p>Inklusion im Sportverein ist ein wichtiger Schritt in Richtung einer gerechten und offenen Gesellschaft. Der Sportverein bietet eine ideale Plattform, um Menschen mit Einschränkungen zu integrieren und ihre Fähigkeiten und Talente zu fördern. Es ist wichtig, dass Sportvereine ihre Angebote entsprechend gestalten und ein Bewusstsein für die Bedürfnisse und Herausforderungen von Menschen mit Einschränkungen schaffen.</p>`,
  //     slug: "inklusion-im-sportverein",
  //     image: "sport_inclusion.jpg"
  //   },
  //   {
  //     createdAt: new Date("2022-11-27"),
  //     headline: "Jugendtraining und -entwicklung im Sport",
  //     content: `<p>Das Jugendtraining und die -entwicklung im Sport sind von entscheidender Bedeutung für die Zukunft eines Vereins. Es ist wichtig, dass junge Sportlerinnen und Sportler frühzeitig an den Sport herangeführt werden und dabei nicht nur ihre sportlichen Fähigkeiten verbessern, sondern auch soziale Kompetenzen entwickeln.</p>
  //     <p>Ein gutes Jugendtraining sollte daher nicht nur die sportliche Leistung fördern, sondern auch die Persönlichkeitsentwicklung der Jugendlichen unterstützen. Hierfür ist es wichtig, dass die Trainerinnen und Trainer neben der Vermittlung von Technik und Taktik auch auf die Entwicklung sozialer Fähigkeiten und Werte wie Teamgeist, Fairness und Respekt vor Gegnern und Schiedsrichtern achten.</p>
  //     <p>Dabei sollten die Trainingsinhalte alters- und entwicklungsgerecht gestaltet werden. Junge Sportlerinnen und Sportler sollten nicht überfordert, aber auch nicht unterfordert werden. Ein ausgewogenes Training, das die individuellen Stärken und Schwächen berücksichtigt, ist hierbei von großer Bedeutung.</p>
  //     <p>Neben dem Training auf dem Platz ist auch die Förderung der mentalen Stärke und der Motivation der Jugendlichen von großer Bedeutung. Hier können die Trainerinnen und Trainer durch gezielte Gespräche und Feedback unterstützen.</p>
  //     <p>Ein weiterer wichtiger Aspekt des Jugendtrainings ist die Integration von Mädchen und Jungen. Mädchen im Sport zu fördern und zu unterstützen ist von großer Bedeutung für die Gleichberechtigung und eine gesunde Entwicklung der Jugendlichen.</p>
  //     <p>Zusammenfassend lässt sich sagen, dass ein gutes Jugendtraining nicht nur die sportliche Leistung, sondern auch die Persönlichkeitsentwicklung der Jugendlichen fördert. Die Trainerinnen und Trainer sollten hierbei sowohl auf die Vermittlung von Technik und Taktik als auch auf die Entwicklung sozialer Fähigkeiten und Werte achten. Die Trainingsinhalte sollten alters- und entwicklungsgerecht gestaltet werden und auch die Förderung der mentalen Stärke und Motivation der Jugendlichen sollte nicht vernachlässigt werden. Die Integration von Mädchen und Jungen ist hierbei ein weiterer wichtiger Aspekt.</p>`,
  //     slug: "jugendtraining-und-entwicklung-im-sport",
  //     image: "youth-development.jpg"
  //   },
  //   {
  //     createdAt: new Date("2022-11-23"),
  //     headline: "Wie man durch gutes Teammanagement die Teamdynamik im Mannschaftssport fördert",
  //     content: `<p>Teammanagement und Teamdynamik sind zwei Schlüsselfaktoren, die im Mannschaftssport eine entscheidende Rolle spielen. Ob es um Fußball, Basketball, Volleyball oder eine andere Mannschaftssportart geht, das Zusammenspiel im Team ist unerlässlich, um Siege zu erringen und erfolgreich zu sein. In diesem Artikel werden wir uns mit einigen Tipps und Tricks befassen, wie man ein Team effektiv managen und eine positive Teamdynamik schaffen kann.</p>
  //     <p>1. Gemeinsame Ziele setzen: Um als Team erfolgreich zu sein, müssen alle Mitglieder auf dasselbe Ziel hinarbeiten. Es ist wichtig, klare Ziele zu definieren und diese mit dem Team zu teilen. Jeder Spieler sollte verstehen, was von ihm erwartet wird, und welche Rolle er im Team hat.</p>
  //     <p>2. Offene Kommunikation: Eine offene und effektive Kommunikation ist der Schlüssel zu einer guten Teamdynamik. Es ist wichtig, dass alle Mitglieder des Teams miteinander sprechen und ihre Gedanken und Ideen teilen können. Eine offene Kommunikation schafft Vertrauen und verbessert das Verständnis füreinander.</p>
  //     <p>3. Stärken und Schwächen erkennen: Jeder Spieler hat Stärken und Schwächen. Als Trainer oder Betreuer ist es wichtig, diese zu erkennen und zu nutzen. Indem man die Stärken jedes Spielers nutzt und Schwächen gezielt verbessert, kann man ein starkes und ausgewogenes Team aufbauen.</p>
  //     <p>4. Regelmäßiges Feedback geben: Ein regelmäßiges Feedback ist wichtig, um die Leistung jedes Spielers zu verbessern. Es ist wichtig, sowohl positives als auch konstruktives Feedback zu geben, um das Selbstvertrauen der Spieler zu stärken und ihnen zu helfen, sich zu verbessern.</p>
  //     <p>5. Team-Building-Aktivitäten: Team-Building-Aktivitäten wie gemeinsame Ausflüge, Trainingslager und andere Aktivitäten außerhalb des Sports können die Teamdynamik stärken und das Zusammengehörigkeitsgefühl verbessern. Durch gemeinsame Erlebnisse lernt man sich besser kennen und kann Vertrauen aufbauen.</p>
  //     <p>6. Motivation: Motivation ist der Schlüssel zum Erfolg. Als Trainer oder Betreuer ist es wichtig, das Team zu motivieren und zu inspirieren. Eine positive Einstellung und eine klare Vision können das Team zu großen Leistungen anspornen.</p>
  //     <p>7. Konflikte lösen: Konflikte sind unvermeidlich, wenn eine Gruppe von Menschen zusammenarbeitet. Als Trainer oder Betreuer ist es wichtig, Konflikte frühzeitig zu erkennen und zu lösen. Konflikte sollten offen und ehrlich angesprochen werden, um eine positive Teamdynamik aufrechtzuerhalten.</p>
  //     <p>Insgesamt gibt es viele Faktoren, die dazu beitragen können, eine erfolgreiche Mannschaft im Sport zu formen. Eine offene Kommunikation, klare Ziele, regelmäßiges Feedback und Team-Building-Aktivitäten sind nur einige der Schlüsselkomponenten.</p> `,
  //     slug: "wie-man-durch-gutes-teammanagement-die-teamdynamik-im-mannschaftssport-fordert",
  //     image: "team-dynamic.jpg"
  //   },
  //   {
  //     createdAt: new Date("2022-11-18"),
  //     headline: "10 motivierende und inspirierende Zitate von Sporlerinnen und Sportlern",
  //     content: `<p>Große Sportlerinnen und Sportler sind nicht umsonst dort wo sie sind. Sie haben hart gearbeitet und Ihre Ziele intensiv verfolgt. Hier ist eine Auflistung von Zitaten von großen Sportlern:</p>
  //     <ol>
  //     <li><q>Der Erfolg ist nicht das Ergebnis von Siegen. Siegen ist das Ergebnis von harter Arbeit, Ausdauer, Lernen, Opferbereitschaft und vor allem Liebe zum, was man tut.</q> - Pelé</li>
  //     <li><q>Es ist nicht die Größe des Hundes im Kampf, sondern die Größe des Kampfes im Hund.</q> - Archie Griffin</li>
  //     <li><q>Es gibt keine Grenzen. Weder für Gedanken, noch für Gefühle. Es ist die Angst, die immer Grenzen setzt.</q> - Michael Jordan</li>
  //     <li><q>Sportler sind moderne Gladiatoren.</q> - Kareem Abdul-Jabbar</li>
  //     <li><q>Wenn du Angst hast zu verlieren, kann es sein, dass du niemals gewinnen wirst.</q> - Bjorn Borg</li>
  //     <li><q>Das Schwierige am Sport ist nicht, körperlich zu sein, sondern mental.</q> - Novak Djokovic</li>
  //     <li><q>Die meisten Menschen geben auf, wenn sie kurz vor dem Erfolg stehen. Sie geben auf, anstatt noch einen Schritt weiterzugehen.</q> - Tony Robbins</li>
  //     <li><q>Es gibt nur einen Weg, um zu gewinnen, und das ist, besser zu spielen als der Gegner.</q> - Arthur Ashe</li>
  //     <li><q>Sport hat die Macht, die Welt zu verändern. Er hat die Macht, Menschen zu inspirieren. Er hat die Macht, Menschen in einer Art und Weise zu vereinen, wie es sonst nur wenige Dinge können.</q> - Nelson Mandela</li>
  //     <li><q>Um Erfolg zu haben, muss man hart arbeiten, hart spielen und vor allem, man muss Spaß haben.</q> - Rafael Nadal</li>
  //   </ol>`,
  //     slug: "10-motivierende-und-inspirierende-zitate-von-sporlerinnen-und-sportlern",
  //     image: "motivational_quotes.jpg"
  //   },
   
   
  
  
  //   // {
  //   //   createdAt: new Date("2023-02-05"),
  //   //   headline: "",
  //   //   content: ``,
  //   //   slug: "",
  //   //   image: ""
  //   // },
    
  //   // {
  //   //   createdAt: new Date("2023-02-05"),
  //   //   headline: "",
  //   //   content: ``,
  //   //   slug: "",
  //   //   image: ""
  //   // },
  //   // {
  //   //   createdAt: new Date("2023-02-05"),
  //   //   headline: "",
  //   //   content: ``,
  //   //   slug: "",
  //   //   image: ""
  //   // },
  // ];



  if (contentSlug !== undefined) {

    const entry = blogEntries.filter( (el :any)=> el.slug === contentSlug)[0];
    return (
      <>
        <Link style={{ display: 'block', color: 'black', marginLeft: '40px', marginTop: '20px' }} to="/blog">Zurück zu allen Blog-Artikeln</Link>
        <BlogEntry entry={entry} key="0" overview={false} />
      </>
    );
  }


  return (
    <div id="blog" style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
      {blogEntries.map((entry : any ) => {
        return <BlogEntry key={entry.slug} entry={entry} overview={true} />
      })
      }
    </div>
  )

}


const BlogEntry = ({ key, entry, overview }: { key: string, entry: BlogEntryType, overview: boolean }) => {


  const date = new Date(entry.publishedAt);
  const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

  const content = overview ? entry.content.substring(0, 500) + "..." : entry.content;

  return (
    <Paper id="blog_content" elevation={3} sx={{ margin: '40px', padding: '40px', display: 'flex', flexDirection: 'column' }} square={false}>
      <Grid container spacing={0}>

        <Grid item xs={12} >
          <div style={{ textAlign: 'end' }}>{formattedDate}</div>
        </Grid>
        <Grid item xs={12} >

          <h1 style={{ lineHeight: '1.1em' }}>{entry.headline}</h1>
        </Grid>


        {/* sx={{ display: { xs: 'block', sm: 'none' } }} */}
        <Grid item xs={12}>

          <Grid container>
            <Grid item xs={12} lg={9}>

              <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: content }} />
            </Grid>
            <Grid item xs={12} lg={3} sx={{textAlign:'center'}}>
              <img src={entry.image} style={{ width: '80%' }} />
            </Grid>
          </Grid>
        </Grid>

        {overview &&
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Link to={`/blog/${entry.slug}`} style={{ fontSize: 'larger', color: 'black' }}>Weiterlesen</Link>
            </div>
          </Grid>
        }
      </Grid>
    </Paper>
  )
};



const NavigationSideBar = ({ sections, showMenu, setShowMenu }: { sections: DocumentationSection[], showMenu: boolean, setShowMenu: any }) => {

  const navigationSections = sections.map(el => {
    return <NavigationSection key={el.id} section={el} setShowMenu={setShowMenu} />
  });

  return <div id="documentation_menu" className={showMenu ? "active" : ""}>{navigationSections}</div>
}



const NavigationSection = ({ section, setShowMenu }: { section: DocumentationSection, setShowMenu: any }) => {

  const navigationContentLinks = section.documentationContents.map(el => {
    return <div key={el.slug} className="navigation_link_container" ><Link className="navigation_link" onClick={() => {
      setShowMenu(false); window.scrollTo(0, 0)
    }} to={"/dokumentation/" + el.slug}> {el.title}</Link></div>
  });
  return <div className="navigation_section" >
    <div className="navigation_section_title">{section.title}</div>
    {navigationContentLinks}
  </div>
}

