import React, { useEffect } from 'react';
import { useMatomo } from '@m4tt72/matomo-tracker-react'


export default function PrivacyServiceProviderListing() {

    const { trackPageView } = useMatomo();
    useEffect(() => {
        document.title = 'Datenschutz - Listung der Anbieter';
        trackPageView();
    });


    const serviceProviders = [
        {
            name: "Hetzner Online GmbH",
            productName: "Hetzner Cloud",
            whichDataPoints: "gekürzte IPs, Kontaktdaten von Kunden und potentiellen Kunden",
            service: "Speicherung der Daten auf Servern",
            country: "Deutschland",
            processingBase: "Art. 6 Abs. 1 lit. a,b,f DSGVO",
            uriPrivacyPolicy: "https://www.hetzner.com/de/legal/privacy-policy",
            uriOptOut: ""
        },
        {
            name: "DomainFactory GmbH",
            productName: "DomainFactory",
            whichDataPoints: "Email",
            service: "Versenden von Emails",
            country: "Deutschland",
            processingBase: "Art. 6 Abs. 1 lit. b DSGVO",
            uriPrivacyPolicy: "https://www.df.eu/de/datenschutz/",
            uriOptOut: ""
        },
        {
            name: "Google Ireland Limited",
            productName: "Google Maps",
            whichDataPoints: "Google Account (falls angemeldet) Geräteinformationen (Browser) Eindeutige Identifier von Google, IP Address, vorherige URL",
            service: "Kartenansicht der Sportstätten",
            country: "Irland",
            processingBase: "Art. 6 Abs. 1 lit. b DSGVO",
            uriPrivacyPolicy: "https://policies.google.com/privacy ",
            uriOptOut: "https://tools.google.com/dlpage/gaoptout?hl=de"
        },
        {
            name: "Google Ireland Limited",
            productName: "Google Firebase",
            whichDataPoints: "App spezifischer Token für das aktuelle Gerät ",
            service: "Empfang von App Benachrichtigungen",
            country: "Irland",
            processingBase: "Art. 6 Abs. 1 lit. b DSGVO",
            uriPrivacyPolicy: "https://policies.google.com/privacy ",
            uriOptOut: "https://tools.google.com/dlpage/gaoptout?hl=de"
        },
        {
            name: "Amazon Web Services Inc",
            productName: "Amazon Web Services",
            whichDataPoints: "Email",
            service: "Versenden von Emails",
            country: "Deutschland",
            processingBase: "Art. 6 Abs. 1 lit. b DSGVO",
            uriPrivacyPolicy: "https://aws.amazon.com/de/privacy/",
            uriOptOut: ""
        },

    ];

    const providers: any = [];
    {
        serviceProviders.forEach(record => {
            providers.push(
                <table key={record.name} style={{width:'100%', marginBottom: '50px'}}>
                    <tbody>
                        <tr key="name">
                            <td style={{ fontWeight: 700, width: '50%' }}>Name der Firma</td>
                            <td>{record.name}</td>
                        </tr>

                        <tr key="productName">
                            <td style={{ fontWeight: 700 }}>Produkt</td>
                            <td>{record.productName}</td>
                        </tr>

                        <tr key="whichDataPoints">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>Welche Daten werden verarbeitet?</td>
                            <td>{record.whichDataPoints}</td>
                        </tr>

                        <tr key="service">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>Wie werden die Daten verarbeitet? Was
ist der Grund der Verarbeitung?</td>
                            <td>{record.service}</td>
                        </tr>
                        <tr key="country">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>Land der Verarbeitung</td>
                            <td>{record.country}</td>
                        </tr>

                        <tr key="processingBase">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>Basis der Verarbeitung</td>
                            <td>{record.processingBase}</td>
                        </tr>

                        <tr key="uriPrivacyPolicy">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>URL zur Datenschutzerklärung</td>
                            <td>{record.uriPrivacyPolicy}</td>
                        </tr>

                        <tr key="uriOptOut">
                            <td style={{ fontWeight: 700, verticalAlign: 'top' }}>URL zum Opt-Out falls verfügbar</td>
                            <td>{record.uriOptOut}</td>
                        </tr>
                    </tbody>
                </table>
            );
        })
    }


    // {
    //     name: "",
    //     productName: "",
    //     whichDataPoints: "",
    //     service: "",
    //     country: "",
    //     processingBase: "",
    //     uriPrivacyPolicy: "",
    //     uriOptOut:""
    // }

    <tr>
    <th>Firmen Name</th>
    <th>Produkt</th>
    <th>Welche Daten werden verarbeitet</th>
    <th>Wie werden die Daten verarbeitet? Was
ist der Grund der Verarbeitung?</th>
    <th>Land der Verarbeitung</th>
    <th>Basis der Verarbeitung</th>
    <th>URL zur Datenschutzerklärung</th>
    <th>URL zum Opt-Out falls verfügbar</th>
</tr>


    return (
        <div>
            <div style={{ padding: '20px' }}>
                <h2>Listung der Anbieter</h2>

{providers}
              
                    {/* <thead>
                    <tr>
                        <th>Firmen Name</th>
                        <th>Produkt</th>
                        <th>Welche Daten werden verarbeitet</th>
                        <th>Wie werden die Daten verarbeitet? Was
ist der Grund der Verarbeitung?</th>
                        <th>Land der Verarbeitung</th>
                        <th>Basis der Verarbeitung</th>
                        <th>URL zur Datenschutzerklärung</th>
                        <th>URL zum Opt-Out falls verfügbar</th>
                    </tr>
                </thead> */}
                   


            </div>


        </div>
    )
}